// Get a RTDB instance
import firebase from 'firebase/app'
import 'firebase/database'

export const db = firebase
    .initializeApp({
        apiKey: "AIzaSyAFNcenSiH_bt40wwLRsSvtKkjH41xP4tQ",
        authDomain: "tedcaldwell-669cb.firebaseapp.com",
        databaseURL: "https://tedcaldwell-669cb.firebaseio.com",
        projectId: "tedcaldwell-669cb",
        storageBucket: "tedcaldwell-669cb.appspot.com",
        messagingSenderId: "1062863621634"
    })
    .database()
