<template>
    <div id="app">
        <header class="header">
            <user-menu></user-menu>
        </header>

        <div class="content content-outer">
            <div class="container">
                <router-view/>
            </div>
        </div>
    </div>
</template>

<script>

import UserMenu from '@/components/UserMenu'
import Toc from '@/components/Toc'
import Api from '@/providers/Api'

export default {
    beforeCreate() {
        Api.initFirebase()
    },
}

</script>

<style lang="scss">
#app {
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

.my-pre {
    font-family: "American Typewriter";
    text-align: left;
}

p {
    text-align: left;
    text-indent: 2em;
    margin: 10px 5% 10px 5%;
}

img.photo {
    border: solid 1px #ccc;
    padding: 10px;
    margin: 20px;
    box-shadow: 10px 10px 22px 0px #999;

    &.wide {
        width: 90%;
    }
}

.caption {
    color: #333;
    margin: 10px 0 30px 0;
    font-style: italic;
}

.letter {
    border: solid 1px #ccc;
    box-shadow: 10px 10px 22px 0px #999;
    margin: 30px 5% 30px 5%;
    padding: 40px 0;
    background-color: #f9f7f5;

    hr {
        margin: 30px 40px;
    }
}

.l52 {
    font-family: "American Typewriter";
    font-size: 18px;
}

img {
    width: 50%;
}
</style>
