<template>
    <div class="view-page">
        <toc v-if="!doc"></toc>

        <div v-html="doc">
        </div>
    </div>
</template>

<script>
import Toc from '@/components/Toc'

export default {
    name: 'ViewPage',
    props: {
        path: String
    },
    data() {
        return {
            doc: null // '<h1>Welcome</h1>',
        }
    },
    watch: {
        path: {
            immediate: true,
            handler: function (newVal, oldVal) { // NB: can't use an arrow function here, as we need 'this'!
                // console.log(newVal)
                if (newVal != '/') {
                    this.$http.get(newVal.replace('+', '') + '/index.html')
                        .then(response => {
                            this.doc = response.body
                        })
                } else {
                    this.doc = null
                }
            }
        }
    }
}
</script>

<style lang="scss">
.view-page {
    font-family: Baskerville, serif;
    font-size: 21px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
</style>
