import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/users',
    component: require('@/components/Users').default,
    props: (route) => ({path: route.path}),
  },
  {
    path: '*',
    component: require('@/components/ViewPage').default,
    props: (route) => ({path: route.path}),
  },
  {
    path: '/bev-art',
    component: require('@/components/BevArt').default,
    props: (route) => ({path: route.path}),
  },
  {
    path: '/jazzbase',
    component: require('@/components/JazzBase').default,
    props: (route) => ({path: route.path}),
  },
  {
    path: '/rummoli',
    component: require('@/components/Rummoli').default,
    props: (route) => ({path: route.path}),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
