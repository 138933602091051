import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

export default {

    // globally register all components in the 'src/components' directory (using kebab-case)
    registerComponents() {
        const requireComponent = require.context(
            // The relative path of the components folder
            '@/components',
            // Whether or not to look in subfolders
            false,
            // The regular expression used to match base component filenames
            /[A-Z]\w+\.(vue|js)$/
        )

        requireComponent.keys().forEach(fileName => {

            // Get component config
            const componentConfig = requireComponent(fileName)

            // Get PascalCase name of component
            const componentName = upperFirst(
                camelCase(
                    // Strip the leading `./` and extension from the filename
                    fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
                )
            )

            const kebabName = componentName.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()

            // Register component globally
            Vue.component(
                kebabName,
                // Look for the component options on `.default`, which will
                // exist if the component was exported with `export default`,
                // otherwise fall back to module's root.
                componentConfig.default || componentConfig
            )
        })
    },

    // add a custom Vue mixin to allow a given service to be accessed from any
    // child component, e.g. as 'this.$i18n'
    addCustomMixin(name) {
        Vue.mixin({
            beforeCreate() {
                const options = this.$options
                if (options[name]) {
                    this['$' + name] = options[name]
                } else if (options.parent && options.parent['$' + name]) {
                    this['$' + name] = options.parent['$' + name]
                }
            }
        })
    },

}
