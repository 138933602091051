export default {
    tuneCollections: [
        {
            title: "Intermediate Jazz Conception", format: '.m4a', imageformat: '.png', hasdemo: true,
            tunes: [
                {
                    "title": "Birds Ballad",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 120.69, "page": 1}]
                },
                {title: 'Confirmed', pages: 2, keyframes: [{"time": 0, "page": 0}, {"time": 59.43, "page": 1}]},
                {"title": "Days Ago", "pages": 2, "keyframes": [{"time": 0, "page": 0}, {"time": 70.43, "page": 1}]},
                {"title": "Freedom", "pages": 2, "keyframes": [{"time": 0, "page": 0}, {"time": 61.68, "page": 1}]},
                {"title": "Green Fin", "pages": 2, "keyframes": [{"time": 0, "page": 0}, {"time": 69.41, "page": 1}]},
                {
                    "title": "Melon Island",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 61.19, "page": 1}]
                },
                {"title": "Miles Blues", "pages": 2, "keyframes": [{"time": 0, "page": 0}, {"time": 80.43, "page": 1}]},
                {"title": "Night Eyes", "pages": 2, "keyframes": [{"time": 0, "page": 0}, {"time": 47.18, "page": 1}]},
                {
                    "title": "Splank Street",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 89.44, "page": 1}]
                },
                {"title": "St. Sonny", "pages": 2, "keyframes": [{"time": 0, "page": 0}, {"time": 55.69, "page": 1}]},
                {"title": "Stellar", "pages": 2, "keyframes": [{"time": 0, "page": 0}, {"time": 66.94, "page": 1}]},
                {"title": "Things", "pages": 2, "keyframes": [{"time": 0, "page": 0}, {"time": 68.2, "page": 1}]},
                {
                    "title": "Tranes Thing",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 56.68, "page": 1}]
                },
                {"title": "Voyage", "pages": 2, "keyframes": [{"time": 0, "page": 0}, {"time": 76.18, "page": 1}]},
                {
                    "title": "You Need Not",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 58.95, "page": 1}]
                },
            ]
        },
        {
            title: "Jazz Conception for Bass Lines", format: '.m4a', imageformat: '.png',
            tunes: [
                {
                    "title": "A Doll (Satin Doll)",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 77.94, "page": 1}]
                },
                {
                    "title": "Grease (Slow Blues in Bb)",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 109.93, "page": 1}]
                },
                {
                    "title": "Passage (Rhythm Changes in G)",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 44.19, "page": 1}]
                },
                {
                    "title": "Amen (The Preacher)",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 51.45, "page": 1}]
                },
                {
                    "title": "Great Love (There Is No Greater Love)",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 72.19, "page": 1}]
                },
                {
                    "title": "Proxy (Doxy)",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 63.93, "page": 1}]
                },
                {
                    "title": "Autumn (Autumn Leaves)",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 82.43, "page": 1}]
                },
                {
                    "title": "Groove Blues (Fast Blues in F)",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 59.2, "page": 1}]
                },
                {
                    "title": "Rose (Honeysuckle Rose)",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 50.19, "page": 1}]
                },
                {
                    "title": "Bird Blues (Fast Blues in C)",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 46.94, "page": 1}]
                },
                {
                    "title": "IND Line (Take the A Train)",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 45.94, "page": 1}]
                },
                {
                    "title": "Somewhere (Out of Nowhere)",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 57.2, "page": 1}]
                },
                {
                    "title": "Blue Minor (Blues in C minor)",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 49.18, "page": 1}]
                },
                {
                    "title": "Joes Thing (Medium Blues in F)",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 68.95, "page": 1}]
                },
                {
                    "title": "Total Blues (All Blues)",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 56.94, "page": 1}]
                },
                {
                    "title": "Father Song (Song For My Father)",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 64.94, "page": 1}]
                },
                {
                    "title": "Lunar (Solar)",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 53.19, "page": 1}]
                },
                {
                    "title": "Tunisia (Night in Tunisia)",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 45.45, "page": 1}]
                },
                {
                    "title": "Friends (Just Friends)",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 46.93, "page": 1}]
                },
                {
                    "title": "Miles (So What)",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 64.94, "page": 1}]
                },
                {
                    "title": "Two Plus Two (Four)",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 44.44, "page": 1}]
                },
            ]
        },
        {
            title: "Jazz Conception", format: '.m4a', imageformat: '.png', comment: "For bass soloing", hasdemo: true,
            tunes: [
                {title: 'A Doll', pages: 2},
                {title: 'Amen', pages: 2},
                {title: 'Autumn', pages: 2},
                {title: 'Bird Blues', pages: 2},
                {title: 'Blue Minor', pages: 2},
                {title: 'Father Song', pages: 2},
                {title: 'Friends', pages: 2},
                {title: 'Grease', pages: 2},
                {title: 'Great Love', pages: 2},
                {title: 'Groove Blues', pages: 1},
                {title: 'IND Line', pages: 2},
                {title: 'Joes Thing', pages: 2},
                {title: 'Lunar', pages: 2},
                {title: 'Miles', pages: 1},
                {title: 'Passage', pages: 2},
                {title: 'Proxy', pages: 2},
                {title: 'Rose', pages: 2},
                {title: 'Somewhere', pages: 2},
                {title: 'Total Blues', pages: 2},
                {title: 'Tunisia', pages: 2},
                {title: 'Two Plus Two', pages: 2}
            ]
        },
        {
            title: "Sittin In With The Big Band", format: '.m4a', imageformat: '.png',
            tunes: [
                {
                    "title": "Alianza",
                    "pages": 4,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 47.68, "page": 1}, {
                        "time": 100.93,
                        "page": 2
                    }, {"time": 147.43, "page": 3}]
                },
                {
                    "title": "On Green Dolphin Street",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 77.43, "page": 1}, {
                        "time": 90.18,
                        "page": 0
                    }, {"time": 102.18, "page": 1}]
                },
                {
                    "title": "Get It On",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 68.2, "page": 1}, {
                        "time": 156.7,
                        "page": 0
                    }, {"time": 170.7, "page": 1}]
                },
                {
                    "title": "Greensleeves",
                    "pages": 4,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 50.19, "page": 1}, {
                        "time": 106.44,
                        "page": 2
                    }, {"time": 173.69, "page": 3}]
                },
                {"title": "Jump", "pages": 2, "keyframes": [{"time": 0, "page": 0}, {"time": 93.94, "page": 1}]},
                {
                    "title": "La Suerte de los Tontos",
                    "pages": 3,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 64.2, "page": 1}, {
                        "time": 142.7,
                        "page": 2
                    }, {"time": 171.19, "page": 0}, {"time": 186.19, "page": 1}, {"time": 196.95, "page": 2}]
                },
                {
                    "title": "Secret Love",
                    "pages": 3,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 76.19, "page": 1}, {"time": 178.95, "page": 2}]
                },
                {
                    "title": "Softly as in a Morning Sunrise",
                    "pages": 3,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 53.7, "page": 1}, {
                        "time": 113.2,
                        "page": 2
                    }, {"time": 194.19, "page": 1}, {"time": 215.45, "page": 2}]
                },
                {
                    "title": "The Red Door",
                    "pages": 4,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 61.68, "page": 1}, {
                        "time": 128.18,
                        "page": 2
                    }, {"time": 183.68, "page": 3}, {"time": 218.43, "page": 0}, {"time": 254.18, "page": 3}]
                },
                {
                    "title": "Trofeo de Bolos",
                    "pages": 3,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 58.19, "page": 1}, {"time": 121.19, "page": 2}]
                },

                {
                    "title": "Burritos To Go",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 67.19, "page": 1}, {
                        "time": 118.69,
                        "page": 0
                    }, {"time": 145.19, "page": 1}]
                },
                {"title": "Drummin Man", "pages": 2, "keyframes": [{"time": 0, "page": 0}, {"time": 96.69, "page": 1}]},
                {
                    "title": "Fiesta Latina",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 86.19, "page": 1}, {
                        "time": 172.94,
                        "page": 0
                    }, {"time": 227.7, "page": 1}]
                },
                {
                    "title": "Goodbye My Heart",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 106.18, "page": 1}]
                },
                {
                    "title": "Now What",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 89.45, "page": 1}, {
                        "time": 138.95,
                        "page": 0
                    }, {"time": 146.95, "page": 1}]
                },
                {
                    "title": "Nutcracker Rock",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 69.72, "page": 1}]
                },
                {
                    "title": "Play That Funky Music",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 87.18, "page": 1}]
                },
                {
                    "title": "Sax to the Max",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 87.43, "page": 1}]
                },
                {
                    "title": "Swingin Shanty",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 90.43, "page": 1}, {
                        "time": 108.44,
                        "page": 0
                    }, {"time": 116.69, "page": 1}]
                },
                {
                    "title": "Two and a Half Men",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 99.7, "page": 1}, {
                        "time": 178.2,
                        "page": 0
                    }, {"time": 212.94, "page": 1}]
                },
                {
                    "title": "Vehicle",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 77.18, "page": 1}, {
                        "time": 141.93,
                        "page": 0
                    }, {"time": 160.43, "page": 1}]
                }
            ]
        },
        {
            title: "How To Play Bass In A Big Band", format: '.m4a', imageformat: '.png',
            tunes: [
                {
                    "title": "Basies Blues",
                    "pages": 5,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 55.69, "page": 1}, {
                        "time": 126.93,
                        "page": 2
                    }, {"time": 189, "page": 3}, {"time": 257.68, "page": 4}]
                },
                {
                    "title": "Shufflin In F",
                    "pages": 4,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 69.44, "page": 1}, {
                        "time": 121.69,
                        "page": 2
                    }, {"time": 179.69, "page": 3}]
                },
                {
                    "title": "Funk Unity",
                    "pages": 4,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 85.43, "page": 1}, {
                        "time": 169.18,
                        "page": 2
                    }, {"time": 256.43, "page": 3}]
                },
                {
                    "title": "Morning",
                    "pages": 5,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 71.19, "page": 1}, {
                        "time": 116.69,
                        "page": 2
                    }, {"time": 160.94, "page": 3}, {"time": 214.69, "page": 4}]
                },
                {
                    "title": "Joes Thing",
                    "pages": 3,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 71.43, "page": 1}, {"time": 138.68, "page": 2}]
                },
                {
                    "title": "Leaving",
                    "pages": 4,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 137.68, "page": 1}, {
                        "time": 210.69,
                        "page": 2
                    }, {"time": 284.44, "page": 3}]
                },
                {
                    "title": "Strayhorn",
                    "pages": 4,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 76.7, "page": 1}, {
                        "time": 119.7,
                        "page": 2
                    }, {"time": 182.7, "page": 3}]
                },
            ]
        },
        {
            title: "Swingin Now", format: '.mp3', imageformat: '.png',
            tunes: [
                {
                    "title": "Bye Bye Blackbird",
                    "pages": 4,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 26.43, "page": 1}, {
                        "time": 49.43,
                        "page": 2
                    }, {"time": 186.18, "page": 0}, {"time": 208.43, "page": 1}, {"time": 225.18, "page": 2}]
                },
                {
                    "title": "Have You Met Miss Jones",
                    "pages": 4,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 25.44, "page": 1}, {
                        "time": 48.43,
                        "page": 2
                    }, {"time": 179.19, "page": 0}, {"time": 199.94, "page": 1}, {"time": 216.19, "page": 2}]
                },
                {
                    "title": "I Got Rhythm",
                    "pages": 3,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 44.93, "page": 1}, {
                        "time": 169.43,
                        "page": 0
                    }, {"time": 206.93, "page": 1}]
                },
                {
                    "title": "Just Friends",
                    "pages": 4,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 23.93, "page": 1}, {
                        "time": 46.68,
                        "page": 2
                    }, {"time": 175.68, "page": 0}, {"time": 194.18, "page": 1}, {"time": 211.43, "page": 2}]
                },
                {
                    "title": "On Green Dolphin Street",
                    "pages": 4,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 29.93, "page": 1}, {
                        "time": 35.68,
                        "page": 0
                    }, {"time": 46.43, "page": 1}, {"time": 56.93, "page": 2}, {
                        "time": 185.18,
                        "page": 0
                    }, {"time": 200.43, "page": 1}, {"time": 206.18, "page": 0}, {
                        "time": 216.18,
                        "page": 1
                    }, {"time": 224.93, "page": 2}]
                },
                {
                    "title": "Stella by Starlight",
                    "pages": 4,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 48.93, "page": 2}, {
                        "time": 180.93,
                        "page": 0
                    }, {"time": 203.43, "page": 1}, {"time": 219.93, "page": 2}]
                },
                {
                    "title": "Stompin at the Savoy",
                    "pages": 4,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 32.68, "page": 1}, {
                        "time": 57.93,
                        "page": 2
                    }, {"time": 211.43, "page": 0}, {"time": 238.43, "page": 1}, {"time": 256.93, "page": 2}]
                },
                {
                    "title": "The Days of Wine and Roses",
                    "pages": 4,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 24.43, "page": 1}, {
                        "time": 51.19,
                        "page": 2
                    }, {"time": 192.18, "page": 0}, {"time": 212.93, "page": 1}, {"time": 233.43, "page": 2}]
                },
                {
                    "title": "What is this Thing Called Love",
                    "pages": 4,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 21.18, "page": 1}, {
                        "time": 42.18,
                        "page": 2
                    }, {"time": 162.68, "page": 0}, {"time": 182.93, "page": 1}, {"time": 200.93, "page": 2}]
                }
            ]
        },
        {
            title: "Easy Standards", format: '.mp3', imageformat: '.png',
            tunes: [
                {
                    "title": "After Youve Gone",
                    "pages": 3,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 39, "page": 1}, {
                        "time": 187.93,
                        "page": 0
                    }, {"time": 219.43, "page": 1}]
                },
                {
                    "title": "Blues March",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 82.43, "page": 1}, {
                        "time": 89.94,
                        "page": 0
                    }, {"time": 106.44, "page": 1}, {"time": 113.19, "page": 0}, {
                        "time": 130.19,
                        "page": 1
                    }, {"time": 137.43, "page": 0}, {"time": 154.19, "page": 1}, {
                        "time": 160.69,
                        "page": 0
                    }, {"time": 184.69, "page": 1}]
                },
                {
                    "title": "Lazy Bird",
                    "pages": 3,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 51.18, "page": 1}, {
                        "time": 194.44,
                        "page": 0
                    }, {"time": 238.94, "page": 1}]
                },
                {
                    "title": "Lester Leaps In",
                    "pages": 3,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 41.68, "page": 1}, {
                        "time": 179.18,
                        "page": 0
                    }, {"time": 218.18, "page": 1}]
                },
                {title: 'Mr PC', pages: 2},
                {
                    "title": "Road Song",
                    "pages": 3,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 56.68, "page": 1}, {
                        "time": 173.43,
                        "page": 0
                    }, {"time": 218.93, "page": 1}]
                },
                {
                    "title": "Scrapple From The Apple",
                    "pages": 3,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 45.67, "page": 1}, {
                        "time": 149.67,
                        "page": 0
                    }, {"time": 191.92, "page": 1}]
                },
                {title: 'St James Infirmary', pages: 2},
                {
                    "title": "Summertime",
                    "pages": 3,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 45.42, "page": 1}, {
                        "time": 157.67,
                        "page": 0
                    }, {"time": 183.17, "page": 1}]
                },
            ]
        },
        {
            title: "Easy Standards 1", format: '.mp3', imageformat: '.png', hasdemo: true,
            tunes: [
                {
                    "title": "Aint Misbehavin",
                    "pages": 4,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 46.68, "page": 1}, {
                        "time": 98.93,
                        "page": 2
                    }, {"time": 139.68, "page": 1}, {"time": 163.43, "page": 2}, {
                        "time": 204.43,
                        "page": 0
                    }, {"time": 241.68, "page": 1}, {"time": 261.93, "page": 2}]
                },
                {
                    "title": "Blue Train",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 77.94, "page": 1}, {
                        "time": 93.93,
                        "page": 0
                    }, {"time": 123.94, "page": 1}, {"time": 139.44, "page": 0}]
                },
                {
                    "title": "Killer Joe",
                    "pages": 3,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 63.19, "page": 1}, {
                        "time": 191.94,
                        "page": 0
                    }, {"time": 243.19, "page": 1}]
                },
                {
                    "title": "Impressions",
                    "pages": 4,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 31.94, "page": 1}, {
                        "time": 65.19,
                        "page": 2
                    }, {"time": 94.44, "page": 1}, {"time": 110.94, "page": 2}, {
                        "time": 139.94,
                        "page": 0
                    }, {"time": 170.69, "page": 1}, {"time": 187.69, "page": 2}]
                },
                {
                    "title": "Jive Samba",
                    "pages": 3,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 81.19, "page": 1}, {
                        "time": 143.69,
                        "page": 0
                    }, {"time": 198.94, "page": 1}]
                },
                {
                    "title": "Little Sunflower",
                    "pages": 3,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 85.2, "page": 1}, {
                        "time": 248.95,
                        "page": 0
                    }, {"time": 285.2, "page": 1}]
                },
                {
                    "title": "My Little Suede Shoes",
                    "pages": 3,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 55.69, "page": 1}, {
                        "time": 183.69,
                        "page": 0
                    }, {"time": 235.18, "page": 1}]
                },
                {
                    "title": "Perdido",
                    "pages": 3,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 51.94, "page": 1}, {
                        "time": 105.19,
                        "page": 2
                    }, {"time": 111.45, "page": 1}, {"time": 151.94, "page": 2}, {
                        "time": 158.7,
                        "page": 0
                    }, {"time": 193.45, "page": 1}, {"time": 202.44, "page": 2}]
                },
                {
                    "title": "Work Song",
                    "pages": 2,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 46.19, "page": 1}, {
                        "time": 52.44,
                        "page": 0
                    }, {"time": 70.44, "page": 1}, {"time": 76.94, "page": 0}, {
                        "time": 94.44,
                        "page": 1
                    }, {"time": 101.19, "page": 0}, {"time": 118.94, "page": 1}, {
                        "time": 125.44,
                        "page": 0
                    }, {"time": 143.44, "page": 1}, {"time": 149.69, "page": 0}, {
                        "time": 167.44,
                        "page": 1
                    }, {"time": 173.94, "page": 0}, {"time": 195.94, "page": 1}]
                },
            ]
        },
        {
            title: "Easy Latin Standards", format: '.mp3', imageformat: '.png',
            tunes: [
                {
                    "title": "Gentle Rain",
                    "pages": 3,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 75.68, "page": 1}, {
                        "time": 293.93,
                        "page": 0
                    }, {"time": 360.68, "page": 1}]
                },
                {
                    "title": "Listen Here",
                    "pages": 3,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 43.18, "page": 1}, {
                        "time": 149.94,
                        "page": 0
                    }, {"time": 172.44, "page": 1}]
                },
                {
                    "title": "Manha de Carnaval",
                    "pages": 3,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 36.43, "page": 1}, {
                        "time": 70.44,
                        "page": 2
                    }, {"time": 267.94, "page": 0}, {"time": 295.68, "page": 1}, {"time": 329.69, "page": 2}]
                },
                {title: "Night and Day", "pages": 3},
                {
                    "title": "Poinciana",
                    "pages": 3,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 130.45, "page": 2}, {
                        "time": 297.2,
                        "page": 0
                    }, {"time": 336.45, "page": 1}, {"time": 379.17, "page": 2}]
                },
                {
                    "title": "Recorda Me",
                    "pages": 3,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 60.18, "page": 1}, {
                        "time": 190.94,
                        "page": 0
                    }, {"time": 235.69, "page": 1}]
                },
                {
                    "title": "Softly as in a Morning Sunrise",
                    "pages": 3,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 41.67, "page": 1}, {
                        "time": 62.17,
                        "page": 2
                    }, {"time": 251.92, "page": 0}, {"time": 279.92, "page": 1}, {"time": 296.42, "page": 2}]
                },
                {
                    "title": "Star Eyes",
                    "pages": 3,
                    "keyframes": [{"time": 0, "page": 0}, {"time": 48.2, "page": 1}, {
                        "time": 73.95,
                        "page": 2
                    }, {"time": 260.95, "page": 0}, {"time": 298.95, "page": 1}, {"time": 321.45, "page": 2}]
                },
                {title: 'The Shadow of Your Smile', pages: 3}
            ]
        },
        {
            title: "Freddie Hubbard and More", format: '.mp3', imageformat: '.png',
            tunes: [
                {title: "Crisis", pages: 4},
                {title: 'Gibraltar', pages: 4},
                {title: "Hot House", pages: 3},
                {title: 'Little Bs Poem', pages: 2},
                {title: 'Night and Day', pages: 4},
                {title: 'Red Clay', pages: 3},
                {title: "Round Midnight", pages: 4},
                {title: 'Sky Dive', pages: 6},
                {title: 'Up Jumped Spring', pages: 4}
            ]
        },
        {
            title: "Django Reinhardt", format: '.m4a', imageformat: '.png',
            tunes: [
                {title: "Are You In The Mood", pages: 1},
                {title: "Crepuscule", pages: 2},
                {title: "DJangology", pages: 2},
                {title: "DJangos Castle", pages: 2},
                {title: "Honeysuckle Rose", pages: 2},
                {title: "Limehouse Blues", pages: 2},
                {title: "Minor Swing", pages: 1},
                {title: "My Serenade", pages: 2},
                {title: "Nuages", pages: 2},
                {title: "Swing 42", pages: 2},
            ]
        },
        {
            title: "Jazz Funk", format: '.m4a', imageformat: '.png',
            tunes: [
                {"title": "Alligator Bogaloo", "pages": 1},
                {"title": "Cissy Strut", "pages": 1},
                {"title": "Cold Duck Time", "pages": 1},
                {"title": "Comin Home Baby", "pages": 1},
                {"title": "Mercy Mercy Mercy", "pages": 1},
                {"title": "Put It Where You Want It", "pages": 1},
                {"title": "Sidewinder", "pages": 1},
                {"title": "The Chicken", "pages": 1},
                {"title": "Tom Cat", "pages": 1},
                {"title": "Watermelon Man", "pages": 1},
            ]
        },
        {
            title: "Christmas", format: '.m4a', imageformat: '.png',
            tunes: [
                {title: 'Auld Lang Syne', pages: 2},
                {title: 'Away In A Manger', pages: 2},
                {title: 'Deck The Hall', pages: 1},
                {title: 'Frosty The Snowman', pages: 2},
                {title: 'Go Tell it on the Mountain', pages: 2},
                {title: 'God Rest Ye Merry Gentlemen', pages: 2},
                {title: 'Good King Wenceslas', pages: 2},
                {title: 'Greensleeves', pages: 2},
                {title: 'Hark the Herald', pages: 2},
                {title: 'Home for the Holidays', pages: 2},
                {title: 'I Heard the Bells', pages: 2},
                {title: 'Ill Be Home', pages: 2},
                {title: 'It Came Upon A Midnight Clear', pages: 2},
                {title: 'Jingle Bells', pages: 2},
                {title: 'Joy to the World', pages: 2},
                {title: 'Let it Snow', pages: 2},
                {title: 'O Christmas Tree', pages: 1},
                {title: 'O Come all ye Faithful', pages: 1},
                {title: 'O Little Town Of Bethlehem', pages: 1},
                {title: 'Rudolph', pages: 2},
                {title: 'Silent Night', pages: 2},
                {title: 'Silver Bells', pages: 2},
                {title: 'Snowfall', pages: 2},
                {title: 'The Christmas Song', pages: 2},
                {title: 'The Christmas Waltz', pages: 2},
                {title: 'The First Noel', pages: 2},
                {title: 'Toyland', pages: 2},
                {title: 'Up on the Housetop', pages: 2},
                {title: 'We Three Kings', pages: 1},
                {title: 'We Wish You A Merry Christmas', pages: 1},
            ]
        },
        {
            title: "Jazz Saxophonist", format: '.m4a', imageformat: '.jpg',
            tunes: [
                {title: "Bop Ditty", pages: 1},
                {title: "Boundry Bay", pages: 1},
                {title: "Coffee Break", pages: 1},
                {title: "Dee Nine", pages: 1},
                {title: "Favored Child", pages: 1},
                {title: "For Now", pages: 1},
                {title: "Jays Blues", pages: 1},
                {title: "Remember", pages: 1},
                {title: "Warm", pages: 1},
            ]
        },
        {
            title: "Know Before You Blow", format: '.m4a', imageformat: '.png',
            tunes: [
                {title: 'Bb-Scales-Tr3', pages: 1},
                {title: 'Bb-Session 1-Tr4', pages: 1},
                {title: 'Bb-Session 2-Tr5', pages: 1},
                {title: 'Bb-Session 3-Tr6', pages: 1},
                {title: 'Bb-Swing This-Tr7', pages: 2},

                {title: 'F-Scales-Tr8', pages: 1},
                {title: 'F-Session 1-Tr9', pages: 1},
                {title: 'F-Session 2-Tr10', pages: 1},
                {title: 'F-Session 3-Tr11', pages: 1},
                {title: 'F-Livin The Blues-Tr12', pages: 2},

                {title: 'Eb-Scales-Tr13', pages: 1},
                {title: 'Eb-Session 1-Tr14', pages: 1},
                {title: 'Eb-Session 2-Tr15', pages: 1},
                {title: 'Eb-Session 3-Tr16', pages: 1},
                {title: 'Eb-Doo Dat-Tr-17', pages: 2},

                {title: 'C-Scales-Tr18', pages: 1},
                {title: 'C-Session 1-Tr19', pages: 1},
                {title: 'C-Session 2-Tr 20', pages: 1},
                {title: 'C-Session 3-Tr21', pages: 1},
                {title: 'C-Funky Sea-Tr22', pages: 2},

                {title: 'Ab-Scales-Tr23', pages: 1},
                {title: 'Ab-Session 1-Tr24', pages: 1},
                {title: 'Ab-Session 2-Tr25', pages: 1},
                {title: 'Ab-Session 3-Tr26', pages: 1},
                {title: 'Ab-Shufflin Shuffle-Tr27', pages: 2},

                {title: 'G-Scales-Tr28', pages: 1},
                {title: 'G-Session 1-Tr29', pages: 1},
                {title: 'G-Session 2-Tr30', pages: 1},
                {title: 'G-Session 3-Tr31', pages: 1},
                {title: 'G-Closing Night-Tr32', pages: 2},

                {title: 'D-Scales-Tr33', pages: 1},
                {title: 'D- Session 1-Tr34', pages: 1},
                {title: 'D-Session 2-Tr35', pages: 1},
                {title: 'D-Session 3-Tr36', pages: 1},
                {title: 'D-Easy Street-Tr37', pages: 2},

                {title: 'A-Scales-Tr38', pages: 1},
                {title: 'A Session 1-Tr39', pages: 1},
                {title: 'A Session 2-Tr40', pages: 1},
                {title: 'A Session 3-Tr41', pages: 1},
                {title: 'A-Be Careful-Tr42', pages: 2},

                {title: 'E-Scales-Tr43', pages: 1},
                {title: 'E Session 1-Tr44', pages: 1},
                {title: 'E Session 2-Tr45', pages: 1},
                {title: 'E Session 3-Tr46', pages: 1},
                {title: 'E-Slinkin-tr-tr47', pages: 2},

                {title: 'Db-Scales-Tr48', pages: 1},
                {title: 'Db Session 1-Tr49', pages: 1},
                {title: 'Db Session 2-Tr50', pages: 1},
                {title: 'Db Session 3-Tr51', pages: 1},
                {title: 'Db-Take That-Tr52', pages: 2},
            ]
        },
    ]
}
