import Vue from 'vue'
import VueResource from 'vue-resource'
import App from '@/App'
import router from '@/router'
import store from '@/store'
import Utils from '@/providers/Utils'

import firebase from 'firebase'
import {rtdbPlugin} from "vuefire"
Vue.use(rtdbPlugin)

import VueMarkdown from 'vue-markdown'

Vue.use(VueResource)

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false

let app = null

Utils.registerComponents()

// don't load main app until we have the firebase user (possibly null)
firebase.auth().onAuthStateChanged((fbUser) => {
  //console.log('initial fbUser', fbUser)
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app')
  }
});
