<template>

    <div v-if="isAdmin">
        <div class="float-right">
            <b-btn variant="primary"
                   @click="createUser()">
                Create User
            </b-btn>
        </div>

        <h2>Users</h2>

        <table class="table"
               v-if="users.length">
            <thead>
            <tr>
                <th class="text-left">Email</th>
                <th class="text-left">Name</th>
                <th class="text-left">Nickname</th>
                <th class="text-left">Permissions</th>
                <th></th>
            </tr>
            </thead>
            <tr v-for="(user,i) in users" :index="i"
                v-if="user">
                <td class="text-left">
                    {{ user ? user.email : '' }}
                    <b-badge variant="success"
                             v-if="user.is_admin">
                        Admin
                    </b-badge>
                </td>
                <td class="text-left">
                    {{ user.fullname }}
                </td>
                <td class="text-left">
                    {{ user.name }}
                </td>
                <td class="text-left">
                    <div v-for="perm in permissionList(user)">
                        {{ getPermissionLabel(perm) }}
                    </div>
                </td>
                <td>
                    <b-btn variant="primary" size="sm" class="mr-3"
                           @click="editUser(user)">
                        <b-icon icon="pencil"></b-icon>
                    </b-btn>
                    <b-btn variant="danger" size="sm"
                           @click="deleteUser(user)">
                        <b-icon icon="trash"></b-icon>
                    </b-btn>
                </td>
            </tr>
        </table>

        <!-- edit modal -->

        <b-modal id="userModal" title="Edit User"
                 @ok="handleEditOk()"
                 @cancel="handleEditCancel()">
            <b-form-group label="Email">
                <b-input type="email" v-model="editingUser.email"></b-input>
            </b-form-group>
            <b-form-group label="Name">
                <b-input type="text" v-model="editingUser.fullname"></b-input>
            </b-form-group>
            <b-form-group label="Nickname">
                <b-input type="text" v-model="editingUser.name"></b-input>
            </b-form-group>
            <b-form-group label="Permissions">
                <b-form-checkbox-group stacked
                                       v-model="editingUser.permissionList"
                                       :options="permissionOptions">
                </b-form-checkbox-group>
            </b-form-group>

        </b-modal>
    </div>

</template>

<script>
import Vue from 'vue'
import Api from '@/providers/Api'

export default Vue.extend({
    name: 'Users',
    data() {
        return {
            editingUser: {}
        }
    },
    computed: {
        isAdmin() {
            return Api.userHasPermission('admin')
        },
        users() {
            return _.map(_.keys(this.$store.state.users), (key) => {
                const user = this.$store.state.users[key]
                if (user) {
                    user.id = key
                }
                return user
            })
        },
        permissionOptions() {
            return Api.USER_PERMISSIONS
        }
    },
    methods: {
        permissionList(user) {
            return user.permissions.split(',')
        },
        getPermissionLabel(perm) {
            const opt = _.find(Api.USER_PERMISSIONS, (p) => p.value == perm)
            return opt ? opt.text : ''
        },
        editUser(user) {
            this.editingUser = user
            user.permissionList = this.permissionList(user)
            this.$bvModal.show('userModal')
        },
        handleEditCancel() {
            this.editingUser = {}
            Api.getUsers()
        },
        handleEditOk() {
            const perms = this.editingUser.permissionList.join(',')
            this.editingUser.permissions = perms
            this.editingUser.permissionList = undefined
            Api.updateUser(this.editingUser)
                .then(() => {
                    this.editingUser = {}
                })
        },
        createUser() {
            Api.createUser({
                email: '',
                fullname: '',
                name: '',
                permissions: ''
            })
        },
        deleteUser(user) {
            Api.deleteUser(user)
        },
    },
})
</script>
