import moment from 'moment'
import store from '@/store'
import firebase from 'firebase'
import router from '@/router'

// --------------------------------------------

const api = {

    initFirebase() {
        firebase.auth().onAuthStateChanged((firebaseUser) => {
            this.getUsers()
            // console.log('onAuthStateChanged, user', firebaseUser)
            if (firebaseUser) {
                if (firebaseUser.email) {
                    store.state.currentUserEmail = firebaseUser.email
                    // console.log(firebaseUser.uid)
                }
            }
            this.checkForSignInLink()
        });
    },

    checkForSignInLink() {
        // Confirm the link is a sign-in with email link.
        if (firebase.auth().isSignInWithEmailLink(window.location.href)) {

            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            var email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                email = window.prompt('Please provide your email for confirmation');
            }

            // The client SDK will parse the code from the link for you.
            firebase.auth().signInWithEmailLink(email, window.location.href)
                .then(function (result) {
                    // NB: don't remove 'emailForSignIn' from localstorage, to prevent spurious confirmation popups...
                    window.location.search = '';
                })
                .catch(function (error) {
                });
            return true;
        } else {
            return false;
        }
    },

    signIn(email) {
        if (!_.find(store.state.users, (u) => !!u && u.email == email)) {
            return false
        }

        const auth = firebase.auth()
        const actionCodeSettings = {
            url: window.location.href,
            handleCodeInApp: true
        }

        auth.sendSignInLinkToEmail(email, actionCodeSettings)
            .then(function () {
                window.localStorage.setItem('emailForSignIn', email)
            })
            .catch(function (error) {
                console.log("Couldn't send sign in link", error)
            });

        return true
    },

    signOut() {
        firebase.auth().signOut();
        store.state.currentUserEmail = null
        store.state.currentUser = {}
        router.replace('/')
    },

    getUsers() {
        return firebase.database().ref()
            .child('users')
            .once("value")
            .then(snapshot => {
                store.state.users = snapshot.val()
                // console.log('users', store.state.users)
                store.state.currentUser = _.find(store.state.users, (u) => {
                    return !!u && u.email == store.state.currentUserEmail
                })
            })
    },

    userHasPermission(perm) {
        return store.state.currentUser &&
            store.state.currentUser.permissions &&
            store.state.currentUser.permissions.split(',').indexOf(perm) != -1
    },

    updateUser(user) {
        var userObj = firebase.database().ref().child('users').child(user.id)
        return userObj.update({
            email: user.email,
            fullname: user.fullname,
            name: user.name,
            permissions: user.permissions,
        })
            .then(() => {
                this.getUsers()
            })
    },

    createUser(values) {
        return firebase.database().ref()
            .child('users')
            .push(values)
            .then(() => {
                this.getUsers()
            })
    },

    deleteUser(user) {
        if (confirm('Permanently delete this user?')) {
            return firebase.database().ref()
                .child('users')
                .child(user.id)
                .remove()
                .then(() => {
                    this.getUsers()
                })
        }
    },

    USER_PERMISSIONS: [
        {value: 'admin', text: 'Administrator'},
        {value: 'jazzbase', text: 'JazzBase'},
        {value: 'family', text: 'Family'},
        {value: 'bev_art', text: 'Bev Art'},
        {value: 'bev_art_admin', text: 'Bev Art Admin'},
        {value: 'rummoli', text: 'Rummoli'},
    ],

    getBevArt() {
        return firebase.database().ref()
            .child('bev_art')
            .once("value")
            .then(snapshot => {
                store.state.bev_art = snapshot.val()
            })
    },

    toggleSold(photo) {
        this.toggleLike(photo, 'SOLD')
    },
    togglePlucked(photo) {
        this.toggleLike(photo, 'PLUCKED')
    },
    toggleLike(photo, email = store.state.currentUserEmail) {
        const ba = store.state.bev_art
        var emails = ba[photo]
        let list = []
        if (emails && emails.length) {
            list = emails.split(',')
        }
        if (list.indexOf(email) != -1) {
            list = _.without(list, email)
        } else {
            list.push(email)
        }
        const attrs = {}
        attrs[photo] = list.join(',')
        return firebase.database().ref().child('bev_art').update(attrs)
            .then(() => {
                this.getBevArt()
            })
    },
    getLikeCount(photo) {
        const email = store.state.currentUserEmail
        const ba = store.state.bev_art
        var emails = ba[photo]
        if (emails && emails.length) {
            return emails.split(',').length
        }
        else {
            return 0
        }
    }
}

export default api