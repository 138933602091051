<template>

    <div class="jazz-base">

        <b-tabs pills card vertical
                v-model="currentCollection"
                v-if="!selectedTune">
            <b-tab v-for="collection in tuneCollections"
                    :title="collection.title">
                <em v-if="collection.comment">
                    {{ collection.comment }}
                    <br/>
                    <br/>
                </em>
                <div v-for="tune in collection.tunes">
                    <b-btn block variant="outline-primary" class="mb-3"
                           @click="selectTune(collection, tune)">{{ tune.title }}
                    </b-btn>
                </div>
            </b-tab>
        </b-tabs>

        <!-- show single tune -->
        <div v-if="selectedTune">
            <div class="audio">
                <audio ref="audio" :src="getAudioSource()" controls></audio>
            </div>

            <div class="toolbar">
                <b-btn size="lg" @click="unselectTune">
                    <b-icon icon="house"></b-icon>
                </b-btn>
                <b-btn size="lg" @click="play">
                    <b-icon icon="play"></b-icon>
                </b-btn>
                <b-btn size="lg" @click="pause">
                    <b-icon icon="pause"></b-icon>
                </b-btn>
                <b-btn size="lg" @click="restart">
                    <b-icon icon="skip-backward"></b-icon>
                </b-btn>
                <b-btn size="lg" @click="selectNextTune">
                    <b-icon icon="skip-forward"></b-icon>
                </b-btn>
                <b-btn size="lg" @click="toggleDemo" :variant="demo ? 'default' : 'success'">
                    <b-icon icon="music-note-beamed"></b-icon>
                </b-btn>

                <!--            <button type="button" class="btn btn-default"-->
                <!--                    ng-repeat="image in getCurrentImages(tune)"-->
                <!--                    ng-click="pageNumberClicked($index)"-->
                <!--                    ng-class="currentPage == $index ? 'btn-success' : ''">-->
                <!--                {{ $index + 1 }}-->
                <!--            </button>-->
            </div>
            <div tabindex="0" class="mx-4"
                 @click="scoreClicked">
                <b-img fluid-grow
                       v-for="(image, i) in getCurrentImages()"
                       v-if="currentPage == i"
                       :src="getImageSource(image)"/>
            </div>

        </div>
    </div>

</template>

<script>

import Vue from 'vue'
import firebase from 'firebase'

import Tunes from '@/providers/tunes.js'

export default Vue.extend({
    name: "JazzBase",
    data() {
        return {
            tuneCollections: Tunes.tuneCollections,
            currentCollection: 0,
            selectedTune: null,
            currentPage: 0,
            demo: false,
            time: 0,
            duration: 0
        }
    },
    computed: {},
    methods: {
        selectTune(collection, tune) {
            this.selectedCollection = collection
            this.selectedTune = tune
            this.currentPage = 0
            this.initKeyframes()
        },
        unselectTune() {
            this.selectedTune = null
        },
        selectNextTune() {
            var index = this.selectedCollection.tunes.indexOf(this.selectedTune)
            this.selectedTune = this.selectedCollection.tunes[index + 1]
            this.initKeyframes()
        },
        toggleDemo() {
            this.demo = !this.demo
        },
        getAudioSource() {
            if (this.selectedTune) {
                this.getAudio()
                return 'https://s3.amazonaws.com/jazzbase-ted/media/' +
                    this.selectedCollection.title + '/' +
                    this.selectedTune.title +
                    (this.demo ? ' demo' : '') +
                    this.selectedCollection.format;
            } else {
                return ''
            }
        },
        getImageSource(image) {
            return 'https://s3.amazonaws.com/jazzbase-ted/media/' +
                this.selectedCollection.title + '/' +
                image +
                this.selectedCollection.imageformat
        },
        getCurrentImages() {
            var images = [];
            if (this.selectedTune) {
                for (var i = 1; i <= this.selectedTune.pages; i++) {
                    images.push(this.selectedTune.title + ' ' + i)
                }
            }
            return images
        },
        onCanPlay(e) {
            this.audio = e.target
        },
        toolbarKeyPressed(e) {
            var audio = getAudio()
            if (e.keyCode == 32) { // space
                if (audio.paused) {
                    audio.play();
                } else {
                    audio.pause();
                }
            }
        },
        getAudio() {
            this.audio = this.$refs.audio;
            //console.log(this.audio.duration);
            return this.audio;
        },
        play() {
            this.getAudio().removeEventListener('timeupdate', this.onTimeUpdate);
            this.getAudio().addEventListener('timeupdate', this.onTimeUpdate);
            this.getAudio().play();
        },
        pause() {
            this.getAudio().pause();
        },
        restart() {
            this.currentPage = 0
            this.getAudio().load(0)
        },
        scoreClicked(e) {
            // if (e.clientX < $('.score-page').not('.ng-hide').width() / 2) {
            //     this.currentPage = Math.max(0, this.currentPage - 1)
            // } else {
            this.currentPage = Math.min(this.selectedTune.pages - 1, this.currentPage + 1)
            // }
        },
        pageNumberClicked(index) {
            if (this.currentPage == index) {
                addKeyframe(index)
            } else {
                this.currentPage = index
            }
        },
        initKeyframes() {
            if (!this.selectedTune.keyframes) {
                this.selectedTune.keyframes = [{time: 0, page: 0}]
            }
            this.currentPage = 0
        },
        onTimeUpdate(e) {
            this.time = Math.floor(e.target.currentTime * 100) / 100
            this.duration = e.target.duration
            this.checkForScroll()
        },
        checkForScroll() {
            for (var i = 0; i < this.selectedTune.keyframes.length; i++) {
                var keyframe = this.selectedTune.keyframes[i]
                //console.log(Math.abs(this.time - keyframe.time));
                if (keyframe.time > 0 && Math.abs(this.time - keyframe.time) < .5) {
                    this.currentPage = keyframe.page
                }
            }
        },
        addKeyframe(index) {
            if (this.selectedTune.keyframes == undefined) {
                this.selectedTune.keyframes == [];
            }
            this.selectedTune.keyframes.push({
                time: this.time,
                page: index
            });

            _.each(this.tuneCollections, function (tc) {
                delete tc['$$hashKey'];
                _.each(tc.tunes, function (t) {
                    delete t['$$hashKey'];
                    _.each(t.keyframes, function (kf) {
                        delete kf['$$hashKey'];
                    });
                });
            });

            var index = this.tuneCollections.indexOf(this.selectedCollection);
            //console.log('selected collection: ', this.selectedCollection);
            // var update = {};
            // update[index] = this.selectedCollection;
            // database.ref().update(update);
            //console.log('updated collection: ', index);
            // console.log(this.tuneCollections);
            if (this.selectedTune) {
                console.log(JSON.stringify(this.selectedTune) + ',');
            }
        }

    }
})

</script>

<style lang="scss">

.jazz-base {
    .audio {
        width: 80%;
        position: absolute;
        top: 50px;
        left: 90px;

        audio {
            width: 100%;
            height: 40px;
            background-color: transparent;
        }
    }

    .toolbar {
        position: absolute;
        left: 10px;
        top: 80px;
        z-index: 2000;
        width: 50px;

        button {
            margin-bottom: 5px;
            background-color: #fff;
            color: #333;
        }
    }
}

</style>