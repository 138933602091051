export default {
    deck: [
        {card: '🂡', svg: 'AS', rank: 14, suit: 'spades', color: 'black'},
        {card: '🂱', svg: 'AH', rank: 14, suit: 'hearts', color: 'red'},
        {card: '🃁', svg: 'AD', rank: 14, suit: 'diamonds', color: 'red'},
        {card: '🃑', svg: 'AC', rank: 14, suit: 'clubs', color: 'black'},
        {card: '🂢', svg: '2S', rank: 2, suit: 'spades', color: 'black'},
        {card: '🂲', svg: '2H', rank: 2, suit: 'hearts', color: 'red'},
        {card: '🃂', svg: '2D', rank: 2, suit: 'diamonds', color: 'red'},
        {card: '🃒', svg: '2C', rank: 2, suit: 'clubs', color: 'black'},
        {card: '🂣', svg: '3S', rank: 3, suit: 'spades', color: 'black'},
        {card: '🂳', svg: '3H', rank: 3, suit: 'hearts', color: 'red'},
        {card: '🃃', svg: '3D', rank: 3, suit: 'diamonds', color: 'red'},
        {card: '🃓', svg: '3C', rank: 3, suit: 'clubs', color: 'black'},
        {card: '🂤', svg: '4S', rank: 4, suit: 'spades', color: 'black'},
        {card: '🂴', svg: '4H', rank: 4, suit: 'hearts', color: 'red'},
        {card: '🃄', svg: '4D', rank: 4, suit: 'diamonds', color: 'red'},
        {card: '🃔', svg: '4C', rank: 4, suit: 'clubs', color: 'black'},
        {card: '🂥', svg: '5S', rank: 5, suit: 'spades', color: 'black'},
        {card: '🂵', svg: '5H', rank: 5, suit: 'hearts', color: 'red'},
        {card: '🃅', svg: '5D', rank: 5, suit: 'diamonds', color: 'red'},
        {card: '🃕', svg: '5C', rank: 5, suit: 'clubs', color: 'black'},
        {card: '🂦', svg: '6S', rank: 6, suit: 'spades', color: 'black'},
        {card: '🂶', svg: '6H', rank: 6, suit: 'hearts', color: 'red'},
        {card: '🃆', svg: '6D', rank: 6, suit: 'diamonds', color: 'red'},
        {card: '🃖', svg: '6C', rank: 6, suit: 'clubs', color: 'black'},
        {card: '🂧', svg: '7S', rank: 7, suit: 'spades', color: 'black'},
        {card: '🂷', svg: '7H', rank: 7, suit: 'hearts', color: 'red'},
        {card: '🃇', svg: '7D', rank: 7, suit: 'diamonds', color: 'red'},
        {card: '🃗', svg: '7C', rank: 7, suit: 'clubs', color: 'black'},
        {card: '🂨', svg: '8S', rank: 8, suit: 'spades', color: 'black'},
        {card: '🂸', svg: '8H', rank: 8, suit: 'hearts', color: 'red'},
        {card: '🃈', svg: '8D', rank: 8, suit: 'diamonds', color: 'red'},
        {card: '🃘', svg: '8C', rank: 8, suit: 'clubs', color: 'black'},
        {card: '🂩', svg: '9S', rank: 9, suit: 'spades', color: 'black'},
        {card: '🂹', svg: '9H', rank: 9, suit: 'hearts', color: 'red'},
        {card: '🃉', svg: '9D', rank: 9, suit: 'diamonds', color: 'red'},
        {card: '🃙', svg: '9C', rank: 9, suit: 'clubs', color: 'black'},
        {card: '🂪', svg: 'TS', rank: 10, suit: 'spades', color: 'black'},
        {card: '🂺', svg: 'TH', rank: 10, suit: 'hearts', color: 'red'},
        {card: '🃊', svg: 'TD', rank: 10, suit: 'diamonds', color: 'red'},
        {card: '🃚', svg: 'TC', rank: 10, suit: 'clubs', color: 'black'},
        {card: '🂫', svg: 'JS', rank: 11, suit: 'spades', color: 'black'},
        {card: '🂻', svg: 'JH', rank: 11, suit: 'hearts', color: 'red'},
        {card: '🃋', svg: 'JD', rank: 11, suit: 'diamonds', color: 'red'},
        {card: '🃛', svg: 'JC', rank: 11, suit: 'clubs', color: 'black'},
        {card: '🂭', svg: 'QS', rank: 12, suit: 'spades', color: 'black'},
        {card: '🂽', svg: 'QH', rank: 12, suit: 'hearts', color: 'red'},
        {card: '🃍', svg: 'QD', rank: 12, suit: 'diamonds', color: 'red'},
        {card: '🃝', svg: 'QC', rank: 12, suit: 'clubs', color: 'black'},
        {card: '🂮', svg: 'KS', rank: 13, suit: 'spades', color: 'black'},
        {card: '🂾', svg: 'KH', rank: 13, suit: 'hearts', color: 'red'},
        {card: '🃎', svg: 'KD', rank: 13, suit: 'diamonds', color: 'red'},
        {card: '🃞', svg: 'KC', rank: 13, suit: 'clubs', color: 'black'},
    ]
}