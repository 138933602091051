<template v-if="game.deck.length">

    <div class="rummoli">

        <div class="other-players">
            <div class="other-player"
                 v-for="player in otherPlayers">
                <div class="other-player-name">{{ player.name }}</div>
                <br/>
                <div class="card-stack">
                    <img src="/poker-qr/2B.svg">
                    <div class="card-count">{{ player.hand ? player.hand.length : '' }}</div>
                </div>
                <chip-stack :count="-1"></chip-stack>
                <div class="chip-count">{{ player.chips }}</div>
            </div>
        </div>

        <rummoli-board2 :chips="game.chips"
                        v-if="game.chips">
        </rummoli-board2>

        <!-- message area -->

        <div class="play-by-play">

            <div class="pile" v-if="lastTurn">
                <playing-card :card="lastTurn.card"></playing-card>
            </div>

            <div class="message">
                <template v-if="game.step == 'DEAL'">
                    <div class="message-text">
                        Welcome to Rummoli!
                        <template v-if="iAmDealer">
                            You are dealer.
                        </template>
                        <template v-else>
                            {{ game.dealer.name }} is Dealer.
                        </template>
                    </div>

                    <div class="actions" v-if="iAmDealer">
                        <b-btn @click="deal()">Deal</b-btn>
                    </div>
                </template>

                <template v-if="game.step == 'SWITCH'">
                    <div class="message-text">
                        <template v-if="iAmDealer">
                            Take extra hand?
                        </template>
                        <template v-else>
                            {{ game.dealer.name }} is deciding whether to auction the extra hand.
                        </template>
                    </div>
                    <div class="actions" v-if="iAmDealer">
                        <b-btn size="sm" @click="switchHand()" class="ml-5">Take</b-btn>
                        <b-btn size="sm" @click="auctionHand()">Auction</b-btn>
                    </div>
                </template>

                <template v-if="game.step == 'AUCTION'">
                    <div class="message-text">
                        <template v-if="!iAmDealer">
                            Place your bid for the extra hand!
                        </template>
                    </div>
                    <div class="actions" v-if="iAmDealer">
                        <b-form inline>
                            <label class="mr-2">Sell to</label>
                            <b-form-select class="mr-2"
                                           v-model="game.auctionWinner"
                                           :options="playerOptions">
                            </b-form-select>
                            <label class="mr-2">for</label>
                            <b-form-input type="number" class="mr-2" style="width:60px"
                                          v-model="game.auctionAmount">
                            </b-form-input>
                            <b-btn size="sm" @click="sellHand()">Sell</b-btn>
                        </b-form>
                    </div>
                </template>

                <template v-if="game.step == 'POKER'">
                    <div class="message-text">
                        <template v-if="!iAmDealer">
                            Poker Pot - Call out your best hand!
                        </template>
                    </div>
                    <div class="actions" v-if="iAmDealer">
                        <b-form inline>
                            <label class="mr-2">Give Poker Pot to</label>
                            <b-form-select class="mr-2"
                                           v-model="game.pokerPotWinner"
                                           :options="playerOptions">
                            </b-form-select>

                            <b-btn size="sm" @click="awardPokerPot()">Give</b-btn>
                        </b-form>
                    </div>
                </template>
                <div v-if="game.step == 'PLAY'">
                    <div class="message-text"
                         v-html="nextPlayMessage">
                    </div>
                </div>
            </div>
        </div>

        <div class="bonus-messages">
            <div v-if="game.bonusMessage"
                 v-html="game.bonusMessage">
            </div>
            <div v-if="game.potMessage"
                 v-html="game.potMessage">
            </div>
        </div>

        <!-- my hand -->

        <div class="hand">
            <div class="clickable-card"
                 v-for="(c, i) in me.hand"
                 @click="playCard(me, c)">
                <playing-card :card="c">
                </playing-card>
            </div>
        </div>

        <div class="bank">
            <chip-stack :count="me.chips"></chip-stack>
        </div>

        <b-btn size="sm" class="mb-3"
               @click="newGame()">New Game
        </b-btn>

    </div>

</template>

<script>

    import Vue from 'vue'
    import * as _ from 'lodash'
    import Cards from '@/providers/Cards'
    import {db} from '@/providers/db'

    const steps = ['DEAL', 'SWITCH', 'AUCTION', 'POKER', 'PLAY', 'OUT']

    export default Vue.extend({
        name: "Rummoli",
        data() {
            return {
                game: db.ref('rummoli_games/1'),
                message: ''
            }
        },
        firebase: {
            game: db.ref()
                .child('rummoli_games')
                .child('1')
        },
        computed: {
            me() {
                if (this.$store.state.currentUserEmail == 'tcaldwell@gorges.us') {
                    return this.game.players ? this.game.players[0] : {}
                } else {
                    return this.game.players ? this.game.players[2] : {}
                }
            },
            iAmDealer() {
                return this.me.name == this.game.dealer.name
            },
            otherPlayers() {
                return _.filter(this.game.players, (player) => {
                    return player.name != 'DUMMY' && player != this.me
                })
            },
            realPlayers() {
                return _.filter(this.game.players, (player) => {
                    return player.name != 'DUMMY'
                })
            },
            playerOptions() {
                return _.map(this.realPlayers, (player) => {
                    return {text: player.name, value: player}
                })
            },
            lastTurn() {
                return _.last(this.game.pile)
            },
            nextPlayMessage() {
                if (this.game.potMessage) {
                    return ''
                } else {
                    const turn = _.last(this.game.pile)
                    if (!this.game.pile || !this.game.pile.length) {
                        return `${this.game.leadPlayer.name}'s lead (lowest red or black)`
                    } else if (turn.card.rank == 14) {
                        return `${turn.player.name} leads low ${this.game.leadColor}`
                    } else if (this.game.leadPlayer.name != turn.player.name) {
                        return `${this.game.leadPlayer.name} leads low ${this.game.leadColor}`
                    } else if (this.nextCardIsGone) {
                        const turn = _.last(this.game.pile)
                        return `Next card is gone - ${turn.player.name} leads low ${this.game.leadColor}`
                    } else {
                        const turn = _.last(this.game.pile)
                        return `${turn.player.name} played ${this.describeRank(turn.card.rank)} of ${turn.card.suit}`
                    }
                }
            },
            nextCardIsGone() {
                if (this.game.pile && this.game.pile.length) {
                    const turn = _.last(this.game.pile)
                    const inPile = _.find(this.game.pile, (pileCard) => {
                        return pileCard.card.suit == turn.card.suit && pileCard.card.rank == turn.card.rank + 1
                    })
                    const inDummy = _.find(this.game.players[1].hand, (dummyCard) => {
                        return dummyCard.suit == turn.card.suit && dummyCard.rank == turn.card.rank + 1
                    })
                    return inPile || inDummy
                } else {
                    return false
                }
            },
        },
        methods: {
            newGame() {
                db.ref('rummoli_games/1').set(this.createNewGame())
            },
            saveGame() {
                console.log('save')
                db.ref('rummoli_games/1').set(this.game)
            },
            createNewGame() {
                const players = [
                    {name: 'Ted', hand: [], chips: 100},
                    {name: 'DUMMY', hand: []},
                    {name: 'Marne', hand: [], chips: 100},
                    // {name: 'Brendan', hand: [], chips: 100},
                    // {name: 'Sachiko', hand: [], chips: 100},
                ]
                return {
                    message: 'Welcome to Rummoli!',
                    step: 'DEAL',
                    deck: _.shuffle(Cards.deck),
                    players: players,
                    dummy: players[1],
                    dealer: players[0],
                    auctionWinner: null,
                    pokerPotWinner: null,
                    leadPlayer: null,
                    leadColor: null,
                    auctionAmount: 1,
                    bonusMessage: null,
                    potMessage: null,
                    pile: [],
                    chips: {
                        pokerPot: 0,
                        tenOfSpades: 0,
                        jackOfDiamonds: 0,
                        queenOfClubs: 0,
                        kingOfHearts: 0,
                        aceOfSpades: 0,
                        kingAceOfDiamonds: 0,
                        sequence: 0,
                        pot: 0,
                    }
                }
            },
            deal() {
                _.each(this.game.players, (player) => {
                    player.hand = []
                })
                let p = 0

                for (let i = 0; i < this.game.deck.length; i++) {
                    this.game.players[p].hand.push(this.game.deck[i])
                    p++;
                    if (p >= this.game.players.length) {
                        p = 0
                    }
                }

                _.each(this.game.players, (player) => {
                    this.sortHand(player)
                })

                this.ante()
                this.game.step = 'SWITCH'
            },
            sortHand(player) {
                player.hand = _.sortBy(player.hand, (card) => {
                    return 20 * ['diamonds', 'clubs', 'hearts', 'spades'].indexOf(card.suit) + card.rank
                })
            },
            canPlayCard(player, card) {
                const turn = _.last(this.game.pile)
                const canLead = player.name == this.game.leadPlayer.name && this.isLowestOfCorrectColor(player, card)
                const canContinue = turn != null && card.rank == turn.card.rank + 1 && card.suit == turn.card.suit
                console.log('leadPlayer', this.game.leadPlayer.name, 'canLead', canLead, 'canContinue', canContinue)
                return canLead || canContinue
            },
            isLowestOfCorrectColor(player, card) {
                const lowBlack = _.minBy(
                    _.filter(player.hand, (c) => c.color == 'black'),
                    (c2) => c2.rank
                )
                const isLowBlack = card.color == 'black' && card.rank == lowBlack.rank
                const lowRed = _.minBy(
                    _.filter(player.hand, (c) => c.color == 'red'),
                    (c2) => c2.rank
                )
                const isLowRed = card.color == 'red' && card.rank == lowRed.rank
                console.log(card, 'isLowRed', isLowRed, 'isLowBlack', isLowBlack)
                return (this.game.leadColor == 'red' && isLowRed) ||
                    (this.game.leadColor == 'black' && isLowBlack) ||
                    (this.game.leadColor == null && (isLowBlack || isLowRed))
            },
            playCard(player, card) {
                if (this.canPlayCard(player, card)) {
                    player.hand = _.without(player.hand, card)
                    if (!this.game.pile) {
                        this.game.pile = []
                    }
                    this.game.pile.push({card: card, player: player})
                    this.game.bonusMessage = ' '
                    const amount = this.awardBonus(card, player)
                    if (amount) {
                        this.game.bonusMessage = `<strong>${player.name} gets ${amount} chip${amount == 1 ? '' : 's'}!</strong>`
                    }
                    const pot = this.checkForOut(player)
                    if (pot) {
                        this.game.potMessage = `<strong>${player.name} went out, and gets ${pot} chip${pot == 1 ? '' : 's'}!</strong>`
                    }
                    this.updateLead(player, card)
                    this.saveGame()
                }
            },
            updateLead(player, card) {
                this.game.leadPlayer = player
                if (card.rank == 14 || this.nextCardIsGone) {
                    this.game.leadColor = card.color == 'black' ? 'red' : 'black'
                }
                const card2 = _.find(this.game.leadPlayer.hand, (c) => c.color == this.game.leadColor)
                if (!card2) { // missing color
                    console.log('missing color', this.game.leadColor)
                    const leadIndex = _.findIndex(this.game.players, (p) => p.name == this.game.leadPlayer.name)
                    const nextPlayers = _.concat(
                        _.slice(this.realPlayers, leadIndex + 1),
                        _.slice(this.realPlayers, 0, leadIndex)
                    )
                    console.log('nextPlayer', nextPlayers[0].name)
                    const nextLead = _.find(nextPlayers, (p) => {
                        const colorCard = _.find(p.hand, (c) => {
                            console.log('does ' + p.name + ' have ' + this.game.leadColor)
                            return c.color == this.game.leadColor
                        })
                        console.log('colorCard', colorCard)
                        return colorCard != null
                    })
                    console.log('nextLead', nextLead)
                    if (nextLead) {
                        this.game.leadPlayer = nextLead
                    } else {
                        this.game.leadColor = this.leadColor == 'black' ? 'red' : 'black'
                    }
                }
            },
            awardBonus(card, player) {
                const turn = _.last(this.game.pile)
                const pileSize = this.game.pile.length
                const lastTurn = (pileSize > 1 ? this.game.pile[pileSize - 2] : null)
                const previousTurn = (pileSize > 2 ? this.game.pile[pileSize - 3] : null)
                let key = null
                if (turn.card.rank == 10 && turn.card.suit == 'spades') {
                    key = 'tenOfSpades'
                }
                if (turn.card.rank == 11 && turn.card.suit == 'diamonds') {
                    key = 'jackOfDiamonds'
                }
                if (turn.card.rank == 12 && turn.card.suit == 'clubs') {
                    key = 'queenOfClubs'
                }
                if (turn.card.rank == 13 && turn.card.suit == 'hearts') {
                    key = 'kingOfHearts'
                }
                if (turn.card.rank == 14 && turn.card.suit == 'spades') {
                    key = 'aceOfSpades'
                }
                if (turn.card.rank == 14 && turn.card.suit == 'diamonds' &&
                    lastTurn && lastTurn.player.name == turn.player.name &&
                    lastTurn.card.rank == 13 && lastTurn.card.suit == 'diamonds') {
                    key = 'kingAceOfDiamonds'
                }
                if (turn.card.rank == 9) {
                    const suit = turn.card.suit
                    if (lastTurn && lastTurn.player.name == turn.player.name &&
                        lastTurn.card.rank == 8 && lastTurn.card.suit == suit &&
                        previousTurn && previousTurn.player.name == turn.player.name &&
                        previousTurn.card.rank == 7 && previousTurn.card.suit == suit) {
                        key = 'sequence'
                    }
                }
                if (key) {
                    const lastPlayer = _.find(this.game.players, (player) => {
                        return player.name == turn.player.name
                    })
                    const amount = this.game.chips[key]
                    lastPlayer.chips += amount
                    this.game.chips[key] = 0
                    return amount
                } else {
                    return 0
                }
            },
            checkForOut(player) {
                if (!player.hand.length) {
                    const oldChips = player.chips
                    player.chips += this.game.chips.pot
                    this.game.chips.pot = 0
                    _.each(this.realPlayers, (p) => {
                        if (p.name != player.name) {
                            player.chips += p.hand.length
                            p.chips -= p.hand.length
                        }
                    })
                    // TODO: set next dealer
                    return player.chips - oldChips
                } else {
                    return 0
                }
            },
            ante() {
                _.each(this.realPlayers, (player) => {
                    _.each(_.keys(this.game.chips), (key) => {
                        this.game.chips[key] += 1
                        player.chips -= 1
                    })
                })
            },
            switchHand() {
                const oldHand = this.me.hand
                this.me.hand = this.game.players[1].hand
                this.game.players[1].hand = oldHand
                this.game.step = 'POKER'
            },
            auctionHand() {
                this.game.step = 'AUCTION'
            },
            sellHand() {
                const oldHand = this.game.auctionWinner.hand
                this.game.auctionWinner.hand = this.game.players[1].hand
                this.game.players[1].hand = oldHand
                this.game.auctionWinner.chips -= +this.game.auctionAmount
                this.game.dealer.chips += +this.game.auctionAmount
                this.game.step = 'POKER'
            },
            awardPokerPot() {
                this.game.pokerPotWinner.chips += +this.game.chips.pokerPot
                this.game.chips.pokerPot = 0
                this.game.leadPlayer = this.game.pokerPotWinner
                this.game.step = 'PLAY'
            },
            describeRank(rank) {
                return [2, 3, 4, 5, 6, 7, 8, 9, 10, 'Jack', 'Queen', 'King', 'Ace'][rank - 2]
            }
        },
        watch: {
            'game.step': {
                immediate: false,
                deep: true,
                handler: function (newVal, oldVal) {
                    if (oldVal) {
                        this.saveGame()
                    }
                }
            }
        }
    })

</script>


<style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Six+Caps&display=swap');

    .rummoli {
        font-family: Arial, Helvetica;

        .play-by-play {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            position: relative;
            font-size: 16px;
            background-color: #ffeeba;
            margin-top: 5px;
            padding: 10px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            height: 120px;

            @media (max-width: 375px) {
                height: 70px;
            }


            .pile {
                flex: .5 1 auto;
                width: 50px;
            }

            .message {
                flex: 2 1 auto;
                display: flex;
                flex-direction: row;

                .message-text {
                    flex: 1 1 auto;
                    font-weight: bold;
                    font-size: 1.2em;
                    @media (max-width: 375px) {
                        font-size: 1em;
                    }
                }

                .actions {
                    flex: 1 1 auto;
                    white-space: nowrap;

                    select {
                        width: 100px;
                    }
                }
            }
        }

        .bonus-messages {
            color: red;
            margin-top: 5px;
        }

        .other-players {
            color: #fff;
            /*width: 600px;*/
            background-color: green;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            padding: 0 10px;
            text-align: left;
            margin-bottom: 3px;

            @media (max-width: 375px) {
                width: 100%;
            }

            .other-player-name {
                position: absolute;
                text-transform: uppercase;
            }

            .other-player {
                height: 80px;
                font-size: 15px;
                position: relative;

                .chip-stack {
                    position: absolute;
                    left: 35px;
                    display: inline-block;
                    width: 35px;
                    font-size: .6em;
                }

                .chip-count {
                    position: absolute;
                    z-index: 2;
                    color: #fff;
                    top: 57px;
                    left: 45px;
                    font-size: 15px;
                }

                .card-stack {
                    position: absolute;
                    display: inline-block;
                    height: 32px;
                    width: 22px;
                    z-index: 2;

                    img {
                        position: absolute;
                        height: 32px;
                        width: 22px;
                        z-index: -100;
                    }

                    .card-count {
                        position: absolute;
                        z-index: 2;
                        color: #fff;
                        top: 35px;
                        font-size: 15px;
                    }
                }
            }
        }

        .hand {
            margin-top: 10px;
            overflow-x: scroll;

            .clickable-card {
                display: inline-block;
                cursor: pointer;
            }
        }

        .bank {
        }

        button {
            background-color: green;
            font-family: Arial, Helvetica;
            text-transform: uppercase;
            /*font-size: 48px;*/
            margin-right: 10px;
        }
    }
</style>