<template>
    <div class="toc">

        <b-row>
            <b-col cols="12" md="6" offset-md="3" class="mb-5">
                <b-card v-if="hasPermission('bev_art')"
                        img-src="/bev art sample.JPG" img-left>
                    <b-card-text>
                        <h3>
                            <router-link to="/bev-art">Art by Bev</router-link>
                        </h3>
                        By special invitation - browse a collection of art by
                        Bev Caldwell and tag your favorites
                    </b-card-text>
                </b-card>
            </b-col>
            <b-col cols="12" md="6" offset-md="3" class="mb-5">
                <b-card v-if="isAdmin"
                        img-src="/rowles-brown-book/charles spurgeon rowles.jpeg" img-right>
                    <b-card-text>
                        <h3>
                    <router-link to="/rowles-brown-book+">The Rowles "Brown Book"</router-link>
                        </h3>
                    </b-card-text>
                </b-card>
            </b-col>
            <b-col cols="12" md="6" offset-md="3" class="mb-5">
                <b-card v-if="isAdmin">
                    <router-link to="/christmas-letters/1952+">Christmas Letters: 1952</router-link>
                </b-card>
            </b-col>
            <b-col cols="12" md="6" offset-md="3" class="mb-5">
                <b-card v-if="isAdmin">
                    <router-link to="/jazzbase">JazzBase</router-link>
                </b-card>
            </b-col>
            <b-col cols="12" md="6" offset-md="3" class="mb-5">
                <b-card v-if="isAdmin">
                    <router-link to="/rummoli">Rummoli</router-link>
                </b-card>
            </b-col>
            <b-col cols="12" md="6" offset-md="3" class="mb-5">
                <b-card v-if="isAdmin">
                    <router-link to="/users">Users</router-link>
                </b-card>
            </b-col>
        </b-row>

    </div>
</template>

<script>
import Vue from 'vue'
import Api from '@/providers/Api'

export default Vue.extend({
    name: "Toc",
    data() {
        return {
            email: null,
            signingIn: null,
        }
    },
    computed: {
        isAdmin() {
            return Api.userHasPermission('admin')
        },
        signedIn() {
            return this.$store.state.currentUserEmail != null
        }
    },
    methods: {
        hasPermission(perm) {
            return Api.userHasPermission('admin') || Api.userHasPermission(perm)
        },
    },
})
</script>

<style lang="scss">
.toc {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    .card {
        h3 {
            text-indent: 0;
        }
    }
}
</style>
