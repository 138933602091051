<template>

    <div class="user-menu">
        <b-navbar toggleable="lg">
            <b-navbar-brand to="/">tedcaldwell.org</b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="ml-auto text-right">
                    <b-nav-text right
                                v-if="userEmail != null">
                        {{ userEmail }}
                    </b-nav-text>

                    <b-nav-item>
                        <b-btn size="sm" variant="outline-primary"
                               v-if="signedIn"
                               @click="signOut()">
                            Sign Out
                        </b-btn>

                        <b-btn variant="outline-primary"
                               v-if="!signedIn"
                               @click="showSignInModal()">
                            Sign In
                        </b-btn>
                    </b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>

        <!-- sign in modal -->

        <b-modal id="signin-modal" title="Sign In">

            <b-form-group label="Enter your email address:">
                <b-form-input type="email"
                              v-model="email">
                </b-form-input>
            </b-form-group>

            <div v-if="signingIn === null">
                <b-alert variant="info" show>
                    If you are deemed worthy, you will receive a sign-in link via email.
                </b-alert>
            </div>
            <div v-if="signingIn === true">
                <b-alert variant="success" show>
                    <strong>Congratulations!</strong>
                    A sign-in link has been sent to your email.
                    <br/>
                    <br/>
                    Now close this browser window - clicking the link in the email will
                    open a new browser window, and you will be signed in - no password is required!
                </b-alert>
            </div>
            <div v-if="signingIn === false">
                <b-alert variant="danger" show>
                    Sorry, you aren't on the list.
                </b-alert>
            </div>

            <!-- modal footer -->
            <template v-slot:modal-footer>
                <div>
                    <b-btn variant="primary" class="float-right"
                           v-if="signingIn === null"
                           :disabled="!emailIsValid"
                           @click="signIn()">
                        Request Sign-in Link
                    </b-btn>
                </div>
            </template>

        </b-modal>

    </div>

</template>

<script>

import Vue from 'vue'
import Api from '@/providers/Api'

export default Vue.extend({
    name: "UserMenu",
    data() {
        return {
            email: null,
            signingIn: null,
        }
    },
    computed: {
        currentUser() {
            return this.$store.state.currentUser
        },
        isAdmin() {
            return Api.userHasPermission('admin')
        },
        emailIsValid() {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return this.email != '' && re.test(String(this.email).toLowerCase())
        },
        userEmail() {
            return this.$store.state.currentUserEmail
        },
        signedIn() {
            return this.$store.state.currentUserEmail != null
        }
    },
    methods: {
        showSignInModal() {
            this.$bvModal.show('signin-modal')
        },
        signIn() {
            const allowed = Api.signIn(this.email)
            this.signingIn = allowed
        },
        signOut() {
            Api.signOut()
        },
    },

})
</script>

<style lang="scss">
.user-menu {
    background-color: #ddd;
    margin-bottom: 20px;

    nav {
        //height: 54px;
        @media (max-width: 375px) {
            max-width: 350px;
        }

        //#nav-collapse {
        //    z-index: 20;
        //    background-color: #fff;
        //}
    }

    .navbar-brand {
        //font: 'American Typewriter';
        margin-left: 20px;
    }
}
</style>