<template>

    <div class="rummoli-board">
        <img src="rummoli-board.svg"></img>

        <div class="chip-stack-poker-pot">
            <chip-stack :count="100"></chip-stack>
        </div>
        <div class="chip-stack-pot">
            <chip-stack :count="100"></chip-stack>
        </div>

        <div class="bonus-card ten-of-spades">
            <playing-card :card="getCard('TS')"></playing-card>
        </div>
        <div class="chip-stack-ten-of-spades">
            <chip-stack :count="100"></chip-stack>
        </div>

        <div class="bonus-card jack-of-diamonds">
            <playing-card :card="getCard('JD')"></playing-card>
        </div>
        <div class="chip-stack-jack-of-diamonds">
            <chip-stack :count="100"></chip-stack>
        </div>

        <div class="bonus-card queen-of-clubs">
            <playing-card :card="getCard('QC')"></playing-card>
        </div>
        <div class="chip-stack-queen-of-clubs">
            <chip-stack :count="100"></chip-stack>
        </div>

        <div class="bonus-card king-of-hearts">
            <playing-card :card="getCard('KH')"></playing-card>
        </div>
        <div class="chip-stack-king-of-hearts">
            <chip-stack :count="100"></chip-stack>
        </div>

        <div class="bonus-card ace-of-spades">
            <playing-card :card="getCard('AS')"></playing-card>
        </div>
        <div class="chip-stack-ace-of-spades">
            <chip-stack :count="100"></chip-stack>
        </div>

        <div class="bonus-card king-of-diamonds">
            <playing-card :card="getCard('KD')"></playing-card>
        </div>
        <div class="bonus-card ace-of-diamonds">
            <playing-card :card="getCard('AD')"></playing-card>
        </div>
        <div class="chip-stack-ace-of-diamonds">
            <chip-stack :count="100"></chip-stack>
        </div>

        <div class="bonus-card seven-of-clubs">
            <playing-card :card="getCard('7C')"></playing-card>
        </div>
        <div class="bonus-card eight-of-clubs">
            <playing-card :card="getCard('8C')"></playing-card>
        </div>
        <div class="bonus-card nine-of-clubs">
            <playing-card :card="getCard('9C')"></playing-card>
        </div>
        <div class="chip-stack-nine-of-clubs">
            <chip-stack :count="100"></chip-stack>
        </div>
    </div>

</template>

<script>

    import Vue from 'vue'
    import * as _ from 'lodash'
    import Cards from '@/providers/Cards'

    export default Vue.extend({
        name: "RummoliBoard",
        methods: {
            getCard(svg) {
                return _.find(Cards.deck, (card) => {
                    return card.svg == svg
                })
            }
        }
    })

</script>


<style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Six+Caps&display=swap');

    .rummoli-board {
        position: relative;
        width: 550px;

        img {
            width: 100%;
        }

        @media (max-width: 375px) {
            width: 275px;
        }


        .chip-stack-poker-pot {
            position: absolute;
            top: 4%;
            left: 43%;
        }

        .chip-stack-pot {
            position: absolute;
            top: 44%;
            left: 43%;
        }

        .bonus-card {
            position: absolute;

            .playing-card {
                img {
                    /*height: 140px;*/
                    width: auto;
                    @media (max-width: 375px) {
                        height: 70px;
                    }
                    clip-path: inset(0% 60% 60% 0%);
                }
            }
        }

        .ten-of-spades {
            top: 20%;
            left: 64%;
            transform: rotate(45deg);
            @media (max-width: 375px) {
                top: 13.5%;
            }
        }

        .chip-stack-ten-of-spades {
            position: absolute;
            top: 24%;
            left: 59%;
            @media (max-width: 375px) {
                top: 20%;
            }
        }

        .jack-of-diamonds {
            top: 33%;
            left: 77%;
            color: red;
            transform: rotate(90deg);
        }

        .chip-stack-jack-of-diamonds {
            position: absolute;
            top: 44%;
            left: 77%;
        }

        .queen-of-clubs {
            top: 57%;
            left: 67%;
            transform: rotate(135deg);
        }

        .chip-stack-queen-of-clubs {
            position: absolute;
            top: 66.5%;
            left: 67%;
        }

        .king-of-hearts {
            top: 69%;
            left: 41.5%;
            color: red;
            transform: rotate(180deg);
        }

        .chip-stack-king-of-hearts {
            position: absolute;
            top: 78%;
            left: 43%;
        }

        .ace-of-spades {
            top: 57%;
            left: 16%;
            transform: rotate(225deg);
        }

        .chip-stack-ace-of-spades {
            position: absolute;
            top: 67%;
            left: 18%;
        }

        .king-of-diamonds {
            top: 34%;
            left: 6%;
            color: red;
            transform: rotate(265deg);
        }

        .ace-of-diamonds {
            top: 32%;
            left: 6%;
            color: red;
            transform: rotate(275deg);
        }

        .chip-stack-ace-of-diamonds {
            position: absolute;
            top: 43%;
            left: 9.5%;
        }

        .seven-of-clubs {
            top: 7.5%;
            left: 14.5%;
            transform: rotate(305deg);
        }

        .eight-of-clubs {
            top: 6.5%;
            left: 16%;
            transform: rotate(315deg);
        }

        .nine-of-clubs {
            top: 5.5%;
            left: 18%;
            transform: rotate(325deg);
        }

        .chip-stack-nine-of-clubs {
            position: absolute;
            top: 17%;
            left: 20%;
        }
    }
</style>