<template>

    <div class="bev-art">

        <div class="filter">
            Show:
            <b-form-radio-group buttons size="sm"
                                :options="viewOptions"
                                v-model="show">
            </b-form-radio-group>
        </div>

        <!-- customer view -->
        <div v-if="!isAdmin">
            <b-row>
                <b-col cols="12" md="6" offset-md="3" class="intro mb-3">
                    <h1>Bev Caldwell's Art</h1>
                    <p>
                        We have several of Mom's fine artworks that are looking for good homes.
                        You have been specially invited to browse the collection, and mark any that
                        you might like to own.
                    </p>
                    <p>
                        You are under absolutely no obligation - just let us know if anything catches
                        your eye, and we will get in touch to figure out the details.
                    </p>
                    <p>
                        To indicate your interest in an artwork, just click the "Like" button
                        below or beside it.
                    </p>
                    <p>
                        If you don't see an item that you previously liked, that is because it has
                        been marked as sold - you can see it if you select "Items I Liked" at the
                        bottom of the page.
                    </p>
                    <p><em>Note: The ruler shown in the photos is 24 inches long.</em></p>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="6" lg="4"
                       v-for="photo in photoList">

                    <b-card class="mb-4"
                            :img-src="getUrl(photo)"
                            :img-left="photos[photo].portrait">
                        <div class="photo-number">
                            {{ photo.replace('DSC_0', '') }}
                        </div>

                        <b-row>
                            <b-col :cols="photos[photo].portrait ? 12 : 4"
                                   class="mb-2">
                                <b-btn size="sm"
                                       :variant="isLikedByMe(photo) ? 'success' : 'outline-secondary'"
                                       @click="toggleLike(photo)">
                                    <b-icon icon="hand-thumbs-up"></b-icon>
                                    Like
                                </b-btn>
                            </b-col>
                            <b-col :cols="photos[photo].portrait ? 12 : 3"
                                   class="mb-2">
                                <div v-for="c in [getLikeCount(photo)]">
                                <span v-if="c">
                                    {{ c + ' ' + (c == 1 ? 'Like' : 'Likes') }}
                                </span>
                                </div>
                            </b-col>
                            <b-col :cols="photos[photo].portrait ? 12 : 5">
                                <b-btn size="sm" variant="outline-info"
                                       @click="preview(photo)">
                                    <b-icon icon="search"></b-icon>
                                    Preview
                                </b-btn>
                            </b-col>
                        </b-row>
                    </b-card>

                    <b-alert show variant="warning"
                             v-if="isAdmin">
                        <b-btn size="sm" class="float-right"
                               :variant="isSold(photo) ? 'danger' : 'outline-danger'"
                               @click="toggleSold(photo)">
                            <b-icon icon="tags"></b-icon>
                            {{ isSold(photo) ? 'Sold' : '' }}
                        </b-btn>

                        <div v-if="getLikerEmails(photo).length">
                            <strong>Likers:</strong>
                            <div v-for="email in getLikerEmails(photo)">
                                {{ email }}
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </b-alert>

                </b-col>
            </b-row>
        </div>

        <!-- admin view -->

        <div v-if="isAdmin">
            <b-card no-body class="p-0 mb-2"
                    v-for="photo in photoList">
                <b-card-body class="p-0">
                    <b-row no-gutters>
                        <b-col cols="4" md="3">
                            <div class="number-stamp ml-1">
                                {{ photo.replace('DSC_0', '') }}
                            </div>

                            <img class="thumbnail" :src="getUrl(photo)"
                                 :class="{'thumbnail-portrait': photos[photo] && photos[photo].portrait}"
                                 @click="preview(photo)">
                        </b-col>
                        <b-col cols="8" md="9">
                            <span v-for="email in getLikerNames(photo)">
                                {{ email }}
                            </span>
                            <br/>
                            <b-btn size="sm" class="mr-2 mb-2"
                                   :variant="isSold(photo) ? 'danger' : 'outline-danger'"
                                   @click="toggleSold(photo)">
                                <b-icon icon="tags"></b-icon>
                                {{ isSold(photo) ? 'Sold' : '' }}
                            </b-btn>
                            <b-btn size="sm" class="mr-2 mb-2"
                                   :variant="isPlucked(photo) ? 'success' : 'outline-success'"
                                   @click="togglePlucked(photo)">
                                <b-icon icon="bag-check"></b-icon>
                                {{ isPlucked(photo) ? 'Plucked' : '' }}
                            </b-btn>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </div>

        <!-- preview modal -->

        <b-modal id="preview-modal"
                 size="xl"
                 hide-footer
                 title="Preview">
            <div class="text-center">
                <img class="preview-photo"
                     :class="{'preview-photo-portrait': photos[selectedPhoto] && photos[selectedPhoto].portrait}"
                     :src="getUrl(selectedPhoto)">
            </div>
        </b-modal>

    </div>

</template>

<script>

import Vue from 'vue'
import firebase from 'firebase'
import Api from '@/providers/Api'

export default Vue.extend({
    name: "BevArt",
    computed: {
        photoList() {
            const bevArt = this.$store.state.bev_art
            return _.filter(_.keys(this.photos), (p) => {
                if (this.show == 'LIKED') {
                    return !!bevArt[p] && bevArt[p].indexOf(this.$store.state.currentUserEmail) != -1
                } else if (this.show == 'SOLD') {
                    return this.isSold(p)
                } else if (this.show == 'LIKED_UNSOLD') {
                    return this.isLikedBySomeone(p) && !this.isSold(p)
                } else {
                    return !this.isSold(p) || this.isAdmin
                }
            })
        },
        isAdmin() {
            return Api.userHasPermission('admin') ||
                Api.userHasPermission('bev_art_admin')
        },
        viewOptions() {
            const options = []
            if (!this.isAdmin) {
                options.push({value: 'ALL', text: 'All Items'},)
                options.push({value: 'LIKED', text: 'Items I Liked'})
            }
            if (this.isAdmin) {
                options.push({value: 'ALL', text: 'All'},)
                options.push({value: 'SOLD', text: 'Sold'})
                options.push({value: 'LIKED_UNSOLD', text: 'Liked/Unsold'})
            }
            return options
        }
    },
    methods: {
        getUrl(photo) {
            return 'https://firebasestorage.googleapis.com/v0/b/tedcaldwell-669cb.appspot.com/o/bev_art%2F'
                + photo
                + '.JPG?alt=media'
        },
        preview(photo) {
            this.selectedPhoto = photo
            this.$bvModal.show('preview-modal')
        },
        isSold(photo) {
            const bevArt = this.$store.state.bev_art
            return !!bevArt[photo] && bevArt[photo].indexOf('SOLD') != -1
        },
        toggleSold(photo) {
            Api.toggleSold(photo)
        },
        isPlucked(photo) {
            const bevArt = this.$store.state.bev_art
            return !!bevArt[photo] && bevArt[photo].indexOf('PLUCKED') != -1
        },
        togglePlucked(photo) {
            Api.togglePlucked(photo)
        },
        isLikedByMe(photo) {
            const bevArt = this.$store.state.bev_art
            return !!bevArt[photo] && bevArt[photo].indexOf(this.$store.state.currentUserEmail) != -1
        },
        isLikedBySomeone(photo) {
            const bevArt = this.$store.state.bev_art
            return !!bevArt[photo] && bevArt[photo] != ""
        },
        getLikerEmails(photo) {
            const bevArt = this.$store.state.bev_art
            if (bevArt && bevArt[photo]) {
                return _.filter(bevArt[photo].split(','), (email) => {
                    return email != 'SOLD'
                })
            } else {
                return []
            }
        },
        getLikerNames(photo) {
            const bevArt = this.$store.state.bev_art
            if (bevArt && bevArt[photo]) {
                const emails = _.filter(bevArt[photo].split(','), (email) => {
                    return email != 'SOLD'
                })
                return _.map(emails, (email) => {
                    const user = _.find(this.$store.state.users, (u) => {
                        return u.email == email
                    })
                    return user ? user.fullname : null
                })
            } else {
                return []
            }
        },
        toggleLike(photo) {
            Api.toggleLike(photo)
        },
        getLikeCount(photo) {
            return Api.getLikeCount(photo)
        }
    },
    mounted() {
        Api.getBevArt()
    },
    data() {
        return {
            selectedPhoto: null,
            show: 'ALL',
            photos: {
                DSC_0023: {portrait: false},
                DSC_0025: {portrait: false},
                DSC_0026: {portrait: false},
                DSC_0027: {portrait: false},
                DSC_0028: {portrait: false},
                DSC_0029: {portrait: false},
                DSC_0030: {portrait: false},
                DSC_0031: {portrait: false},
                DSC_0032: {portrait: false},
                DSC_0033: {portrait: false},
                DSC_0034: {portrait: true},
                DSC_0035: {portrait: true},
                DSC_0036: {portrait: true},
                DSC_0037: {portrait: true},
                DSC_0038: {portrait: true},
                DSC_0039: {portrait: true},
                DSC_0041: {portrait: true},
                DSC_0042: {portrait: true},
                DSC_0043: {portrait: true},
                DSC_0044: {portrait: true},
                DSC_0045: {portrait: true},
                DSC_0046: {portrait: true},
                DSC_0047: {portrait: true},
                DSC_0048: {portrait: true},
                DSC_0049: {portrait: true},
                DSC_0050: {portrait: true},
                DSC_0051: {portrait: true},
                DSC_0052: {portrait: true},
                DSC_0053: {portrait: true},
                DSC_0054: {portrait: true},
                DSC_0055: {portrait: true},
                DSC_0056: {portrait: true},
                DSC_0057: {portrait: true},
                DSC_0058: {portrait: true},
                DSC_0059: {portrait: true},
                DSC_0060: {portrait: true},
                DSC_0061: {portrait: true},
                DSC_0062: {portrait: true},
                DSC_0063: {portrait: true},
                DSC_0064: {portrait: true},
                DSC_0065: {portrait: true},
                DSC_0066: {portrait: true},
                DSC_0067: {portrait: true},
                DSC_0068: {portrait: true},
                DSC_0069: {portrait: true},
                DSC_0070: {portrait: true},
                DSC_0071: {portrait: true},
                DSC_0072: {portrait: true},
                DSC_0073: {portrait: true},
                DSC_0074: {portrait: true},
                DSC_0075: {portrait: true},
                DSC_0076: {portrait: true},
                DSC_0077: {portrait: true},
                DSC_0078: {portrait: true},
                DSC_0079: {portrait: true},
                DSC_0080: {portrait: true},
                DSC_0081: {portrait: true},
                DSC_0082: {portrait: true},
                DSC_0083: {portrait: true},
                DSC_0084: {portrait: true},
                DSC_0085: {portrait: true},
                DSC_0086: {portrait: true},
                DSC_0087: {portrait: true},
                DSC_0088: {portrait: true},
                DSC_0089: {portrait: true},
                DSC_0090: {portrait: true},
                DSC_0091: {portrait: true},
                DSC_0092: {portrait: true},
                DSC_0093: {portrait: true},
                DSC_0094: {portrait: true},
                DSC_0095: {portrait: true},
                DSC_0096: {portrait: true},
                DSC_0097: {portrait: true},
                DSC_0098: {portrait: true},
                DSC_0099: {portrait: true},
                DSC_0100: {portrait: true},
                DSC_0101: {portrait: true},
                DSC_0102: {portrait: true},
                DSC_0103: {portrait: true},
                DSC_0104: {portrait: true},
                DSC_0105: {portrait: true},
                DSC_0106: {portrait: true},
                DSC_0107: {portrait: true},
                DSC_0108: {portrait: true},
                DSC_0109: {portrait: true},
                DSC_0110: {portrait: true},
                DSC_0111: {portrait: true},
                DSC_0112: {portrait: true},
                DSC_0113: {portrait: true},
                DSC_0114: {portrait: true},
                DSC_0115: {portrait: true},
                DSC_0116: {portrait: true},
                DSC_0117: {portrait: true},
                DSC_0118: {portrait: true},
                DSC_0119: {portrait: true},
                DSC_0120: {portrait: true},
                DSC_0121: {portrait: true},
                DSC_0122: {portrait: true},
                DSC_0123: {portrait: true},
                DSC_0124: {portrait: true},
                DSC_0125: {portrait: true},
                DSC_0126: {portrait: true},
                DSC_0127: {portrait: true},
                DSC_0128: {portrait: true},
                DSC_0129: {portrait: true},
                DSC_0130: {portrait: true},
                DSC_0131: {portrait: true},
                DSC_0132: {portrait: true},
                DSC_0133: {portrait: true},
                DSC_0134: {portrait: true},
                DSC_0135: {portrait: true},
                DSC_0136: {portrait: true},
                DSC_0137: {portrait: true},
                DSC_0138: {portrait: true},
                DSC_0139: {portrait: true},
                DSC_0140: {portrait: true},
                DSC_0141: {portrait: true},
                DSC_0142: {portrait: true},
                DSC_0143: {portrait: true},
                DSC_0144: {portrait: true},
                DSC_0145: {portrait: true},
                DSC_0146: {portrait: true},
                DSC_0147: {portrait: true},
                DSC_0148: {portrait: true},
                DSC_0149: {portrait: true},
                DSC_0150: {portrait: true},
                DSC_0151: {portrait: true},
                DSC_0152: {portrait: true},
                DSC_0153: {portrait: true},
                DSC_0154: {portrait: true},
                DSC_0155: {portrait: true},
                DSC_0156: {portrait: true},
                DSC_0157: {portrait: true},
                DSC_0158: {portrait: false},
                DSC_0159: {portrait: false},
                DSC_0160: {portrait: false},
                DSC_0161: {portrait: false},
                DSC_0162: {portrait: false},
                DSC_0163: {portrait: false},
                DSC_0164: {portrait: false},
                DSC_0165: {portrait: false},
                DSC_0166: {portrait: false},
                DSC_0167: {portrait: false},
                DSC_0168: {portrait: false},
                DSC_0169: {portrait: false},
                DSC_0170: {portrait: false},
                DSC_0171: {portrait: false},
                DSC_0172: {portrait: false},
                DSC_0173: {portrait: false},
                DSC_0174: {portrait: false},
                DSC_0175: {portrait: false},
                DSC_0176: {portrait: false},
                DSC_0177: {portrait: false},
                DSC_0178: {portrait: false},
                DSC_0179: {portrait: false},
                DSC_0180: {portrait: false},
                DSC_0181: {portrait: false},
                DSC_0182: {portrait: false},
                DSC_0183: {portrait: false},
                DSC_0184: {portrait: false},
                DSC_0185: {portrait: false},
                DSC_0186: {portrait: false},
                DSC_0187: {portrait: false},
                DSC_0188: {portrait: false},
                DSC_0189: {portrait: false},
                DSC_0190: {portrait: false},
                DSC_0191: {portrait: false},
                DSC_0192: {portrait: false},
                DSC_0193: {portrait: false},
                DSC_0194: {portrait: false},
                DSC_0195: {portrait: false},
                DSC_0196: {portrait: false},
                DSC_0197: {portrait: false},
                DSC_0198: {portrait: false},
                DSC_0199: {portrait: false},
                DSC_0200: {portrait: false},
                DSC_0201: {portrait: false},
                DSC_0202: {portrait: false},
                DSC_0203: {portrait: false},
                DSC_0204: {portrait: false},
                DSC_0205: {portrait: false},
                DSC_0206: {portrait: false},
                DSC_0207: {portrait: false},
                DSC_0208: {portrait: false},
                DSC_0209: {portrait: false},
                DSC_0210: {portrait: false},
                DSC_0211: {portrait: false},
                DSC_0212: {portrait: false},
                DSC_0213: {portrait: false},
                DSC_0214: {portrait: false},
                DSC_0215: {portrait: false},
                DSC_0216: {portrait: false},
                DSC_0217: {portrait: false},
                DSC_0218: {portrait: false},
                DSC_0219: {portrait: false},
                DSC_0220: {portrait: false},
                DSC_0221: {portrait: false},
                DSC_0222: {portrait: false},
                DSC_0223: {portrait: false},
                DSC_0224: {portrait: false},
                DSC_0225: {portrait: false},
                DSC_0226: {portrait: false},
                DSC_0227: {portrait: false},
                DSC_0228: {portrait: false},
                DSC_0229: {portrait: false},
                DSC_0230: {portrait: false},
                DSC_0231: {portrait: false},
                DSC_0232: {portrait: false},
                DSC_0233: {portrait: false},
                DSC_0234: {portrait: false},
                DSC_0235: {portrait: false},
                DSC_0236: {portrait: false},
                DSC_0237: {portrait: false},
                DSC_0238: {portrait: false},
                DSC_0239: {portrait: false},
                DSC_0240: {portrait: false},
                DSC_0241: {portrait: false},
                DSC_0242: {portrait: false},
                DSC_0243: {portrait: false},
                DSC_0244: {portrait: false},
                DSC_0245: {portrait: false},
                DSC_0246: {portrait: false},
                DSC_0247: {portrait: false},
                DSC_0248: {portrait: false},
                DSC_0249: {portrait: false},
                DSC_0250: {portrait: false},
                DSC_0252: {portrait: false},
                DSC_0253: {portrait: false},
                DSC_0254: {portrait: false},
                DSC_0255: {portrait: false},
                DSC_0256: {portrait: false},
                DSC_0257: {portrait: false},
                DSC_0258: {portrait: false},
                DSC_0259: {portrait: false},
                DSC_0260: {portrait: false},
                DSC_0261: {portrait: false},
                DSC_0262: {portrait: false},
                DSC_0263: {portrait: false},
                DSC_0264: {portrait: false},
                DSC_0265: {portrait: false},
                DSC_0266: {portrait: false},
                DSC_0267: {portrait: false},
                DSC_0268: {portrait: false},
                DSC_0269: {portrait: false},
                DSC_0270: {portrait: false},
                DSC_0271: {portrait: false},
                DSC_0272: {portrait: false},
                DSC_0273: {portrait: false},
                DSC_0274: {portrait: false},
                DSC_0275: {portrait: false},
                DSC_0276: {portrait: false},
                DSC_0277: {portrait: false},
                DSC_0278: {portrait: false},
                DSC_0279: {portrait: false},
                DSC_0280: {portrait: false},
                DSC_0281: {portrait: false},
                DSC_0282: {portrait: false},
                DSC_0283: {portrait: false},
                DSC_0284: {portrait: false},
                DSC_0285: {portrait: false},
                DSC_0286: {portrait: false},
                DSC_0287: {portrait: false},
                DSC_0288: {portrait: false},
                DSC_0289: {portrait: false},
                DSC_0290: {portrait: false},
                DSC_0291: {portrait: false},
                DSC_0292: {portrait: false},
                DSC_0293: {portrait: false},
                DSC_0294: {portrait: false},
                DSC_0295: {portrait: false},
                DSC_0296: {portrait: false},
                DSC_0297: {portrait: false},
                DSC_0298: {portrait: false},
                DSC_0299: {portrait: false},
                DSC_0300: {portrait: false},
                DSC_0301: {portrait: false},
                DSC_0302: {portrait: false},
                DSC_0303: {portrait: false},
                DSC_0304: {portrait: false},
                DSC_0305: {portrait: false},
                DSC_0306: {portrait: false},
                DSC_0307: {portrait: false},
                DSC_0308: {portrait: false},
                DSC_0309: {portrait: false},
                DSC_0310: {portrait: false},
                DSC_0311: {portrait: false},
                DSC_0312: {portrait: false},
                DSC_0313: {portrait: true},
                DSC_0314: {portrait: false},
                DSC_0315: {portrait: false},
                DSC_0316: {portrait: true},
                DSC_0317: {portrait: true},
                DSC_0318: {portrait: true},
                DSC_0319: {portrait: true},
                DSC_0320: {portrait: true},
                DSC_0321: {portrait: true},
                DSC_0322: {portrait: true},
                DSC_0323: {portrait: true},
                DSC_0324: {portrait: true},
                DSC_0325: {portrait: true},
                DSC_0326: {portrait: true},
                DSC_0327: {portrait: true},
                DSC_0328: {portrait: true},
                DSC_0329: {portrait: true},
                DSC_0330: {portrait: true},
                DSC_0331: {portrait: true},
                DSC_0332: {portrait: true},
                DSC_0333: {portrait: true},
                DSC_0334: {portrait: true},
                DSC_0335: {portrait: true},
                DSC_0336: {portrait: true},
                DSC_0337: {portrait: true},
                DSC_0338: {portrait: true},
                DSC_0339: {portrait: true},
                DSC_0340: {portrait: true},
                DSC_0341: {portrait: true},
                DSC_0342: {portrait: true},
                DSC_0343: {portrait: true},
                DSC_0344: {portrait: true},
                DSC_0345: {portrait: true},
                DSC_0346: {portrait: true},
                DSC_0347: {portrait: true},
                DSC_0348: {portrait: true},
                DSC_0349: {portrait: true},
                DSC_0350: {portrait: true},
                DSC_0351: {portrait: true},
                DSC_0352: {portrait: true},
                DSC_0353: {portrait: true},
                DSC_0354: {portrait: true},
                DSC_0355: {portrait: true},
                DSC_0356: {portrait: true},
                DSC_0357: {portrait: true},
                DSC_0358: {portrait: true},
                DSC_0359: {portrait: true},
                DSC_0360: {portrait: true},
                DSC_0361: {portrait: true},
                DSC_0362: {portrait: true},
                DSC_0363: {portrait: true},
                DSC_0364: {portrait: true},
                DSC_0365: {portrait: true},
                DSC_0366: {portrait: true},
                DSC_0367: {portrait: true},
                DSC_0368: {portrait: true},
                DSC_0369: {portrait: true},
                DSC_0370: {portrait: true},
                DSC_0371: {portrait: true},
                DSC_0372: {portrait: true},
                DSC_0373: {portrait: true},
                DSC_0374: {portrait: true},
                DSC_0375: {portrait: true},
                DSC_0376: {portrait: true},
                DSC_0377: {portrait: true},
                DSC_0378: {portrait: true},
            }
        }
    },
})

</script>

<style lang="scss">
.bev-art {
    .intro {
        h1 {
            text-align: center;
        }

        p {
            text-indent: 0;
        }
    }

    .card {
        border: solid 1px #999;
    }

    .card-body {
        //background-color: #f0f0f0;
    }

    .photo-number {
        position: absolute;
        left: 0;
        top: 0;
        padding: 2px 10px;
        border-bottom-right-radius: 5px;
        background-color: rgba(155, 155, 155, .8);
    }

    .filter {
        position: fixed;
        bottom: 0;
        padding: 20px;
        background-color: cornsilk;
        z-index: 1000;
    }

}

img.thumbnail {
    width: 20%;
    min-width: 100px;
}

img.thumbnail-portrait {
    min-width: 60px;
    @media(min-width: 640px) {
        width: 10%;
    }
}

img.preview-photo {
    width: 100%;
}

img.preview-photo-portrait {
    @media(min-width: 640px) {
        width: 66%;
    }
}

.number-stamp {
    color: #fff;
    position: absolute;
}

</style>

ast,people have