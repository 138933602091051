<template>

    <div class="rummoli-board2">

        <b-row>
            <b-col class="text-center">
                <div class="bonus-card">
                    <playing-card :card="getCard('7C')"
                                  style="left:20%"></playing-card>
                    <playing-card :card="getCard('8C')"
                                  style="left:35%"></playing-card>
                    <playing-card :card="getCard('9C')"
                                  style="left:50%"></playing-card>
                </div>
                <chip-stack :count="chips.sequence"></chip-stack>
            </b-col>
            <b-col class="text-center">
                <div class="bonus-card">
                    <div class="poker-pot-heading">Poker Pot</div>
                </div>
                <chip-stack :count="chips.pokerPot"></chip-stack>
            </b-col>
            <b-col class="text-center">
                <div class="bonus-card">
                    <playing-card :card="getCard('TS')"></playing-card>
                </div>
                <chip-stack :count="chips.tenOfSpades"></chip-stack>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="text-center">
                <div class="bonus-card">
                    <playing-card :card="getCard('KD')"
                                  style="left:20%"></playing-card>
                    <playing-card :card="getCard('AD')"
                                  style="left:40%"></playing-card>
                </div>
                <chip-stack :count="chips.kingAceOfDiamonds"></chip-stack>
            </b-col>
            <b-col class="text-center">
                <div class="bonus-card">
                    <div class="poker-pot-heading">Rummoli</div>
                </div>
                <chip-stack :count="chips.pot"></chip-stack>
            </b-col>
            <b-col class="text-center">
                <div class="bonus-card">
                    <playing-card :card="getCard('JD')"></playing-card>
                </div>
                <chip-stack :count="chips.jackOfDiamonds"></chip-stack>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="text-center">
                <div class="bonus-card">
                    <playing-card :card="getCard('AS')"></playing-card>
                </div>
                <chip-stack :count="chips.aceOfSpades"></chip-stack>
            </b-col>
            <b-col class="text-center">
                <div class="bonus-card">
                    <playing-card :card="getCard('KH')"></playing-card>
                </div>
                <chip-stack :count="chips.kingOfHearts"></chip-stack>
            </b-col>
            <b-col class="text-center">
                <div class="bonus-card">
                    <playing-card :card="getCard('QC')"></playing-card>
                </div>
                <chip-stack :count="chips.queenOfClubs"></chip-stack>
            </b-col>
        </b-row>

    </div>

</template>

<script>

    import Vue from 'vue'
    import * as _ from 'lodash'
    import Cards from '@/providers/Cards'

    export default Vue.extend({
        name: "RummoliBoard2",
        props: {chips: Object},
        methods: {
            getCard(svg) {
                return _.find(Cards.deck, (card) => {
                    return card.svg == svg
                })
            }
        }
    })

</script>


<style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Six+Caps&display=swap');

    .rummoli-board2 {
        position: relative;
        background-color: green;

        .chip-stack {
            margin-top:-40px;
        }

        img {
            width: 100%;
        }

        /*@media (max-width: 375px) {*/
        /*    width: 275px;*/
        /*}*/
    }

    .poker-pot-heading {
        position: absolute;
        top: 10%;
        left: 30%;
        color: #fff;
        font-family: 'Six Caps', Arial, Helvetica;
        font-size: 36px;

        @media (max-width: 375px) {
            font-size: 18px;
        }
    }

    .bonus-card {
        height: 120px;
        @media (max-width: 375px) {
            height: 60px;
        }

        .playing-card {
            height: 100px;
            width: 80px;
            overflow: hidden;
            position: absolute;
            top: 10%;
            left: 30%;

            @media (max-width: 375px) {
                height: 50px;
                width: 40px;
                top: 10%;
            }

            img {
                height: 336px;
                width: 240px;
                clip-path: inset(0% 60% 60% 0%);

                @media (max-width: 375px) {
                    height: 168px;
                    width: 120px;
                }
            }
        }
    }

</style>