<template>

    <div class="playing-card">
        <img :src="`/poker-qr/${card.svg}.svg`"></img>
    </div>

</template>

<script>

    import Vue from 'vue'

    export default Vue.extend({
        name: "PlayingCard",
        props: {card: Object},
        computed: {
            isRed() {
                return this.card.suit == 'hearts' || this.card.suit == 'diamonds'
            },
            isBlack() {
                return this.card.suit == 'spades' || this.card.suit == 'clubs'
            },
        }
    })

</script>


<style lang="scss">

    .playing-card {
        height: 100px;
        width: 80px;
        overflow: hidden;
        display: inline-block;

        @media (max-width: 375px) {
            height: 50px;
            width: 40px;
            top: 10%;
        }

        img {
            height: 336px;
            width: 240px;
            clip-path: inset(0% 60% 60% 0%);

            @media (max-width: 375px) {
                height: 168px;
                width: 120px;
            }
        }
    }

</style>